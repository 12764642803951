import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { CarreraService } from 'src/app/services/carrera.service';
import { EventoService } from 'src/app/services/evento.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-eventos-carrera-detalle',
  templateUrl: './eventos-carrera-detalle.component.html',
  styleUrls: ['./eventos-carrera-detalle.component.css']
})
export class EventosCarreraDetalleComponent implements OnInit {

  @Input() cod_carrera;
  @Input() tipo;

  public ListadoEvento: any;
  public ifListadoEventos = false;

  constructor(
    private _EventoService: EventoService,
    private _CarreraService: CarreraService,
    private _GlobalService: GlobalService,
  ) { }

  ngOnInit(): void {
    console.log("cod_carrera",this.cod_carrera);
    console.log("tipo",this.tipo);
    this.GETEvento();
  }

  public GETEvento() {
    this._EventoService.GetCarreraEventos(this.cod_carrera).subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          let length = 0 ;
          datos.forEach((element) => {
            length++;
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            element["hora_inicio"] = element["hora_inicio"].substring(0, 5);
            element["hora_termino"] = element["hora_termino"].substring(0, 5);
          });
          
          console.log(datos);
          let listado = [];

          if(length > 0){
            this.ifListadoEventos = true;
          }

          if (length <= 3) {
            for (let i = 0; i < length; i++) {
              listado.push(datos[i]);
            }
          } else {
            for (let i = 0; i < 3; i++) {
              listado.push(datos[i]);
            }
          }

          this.ListadoEvento = listado;
          console.log(length);
        } else {
          console.log("result", result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        }
      }
    );
  }

}
