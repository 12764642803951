import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { Routing } from './app.routing';

//Translation
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
// importar locales
import localePy from '@angular/common/locales/es-PY';
import localePt from '@angular/common/locales/pt';
import localeEn from '@angular/common/locales/en';
//Material
import {A11yModule} from '@angular/cdk/a11y';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {PortalModule} from '@angular/cdk/portal';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {CdkTableModule} from '@angular/cdk/table';
import {CdkTreeModule} from '@angular/cdk/tree';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatButtonModule} from '@angular/material/button';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatPaginatorModule, MatPaginatorIntl} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSliderModule} from '@angular/material/slider';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatTreeModule} from '@angular/material/tree';
import {MatFormFieldModule} from '@angular/material/form-field';
//Servicios
import { ContenidoService } from './services/contenido.service';
import { GlobalService } from './services/global.service';
import { UsuarioService } from './services/usuario.service';
import { SliderService } from './services/slider.service';
import { EventoService } from './services/evento.service';
import { NoticiaService } from './services/noticia.service';
import { CarreraService } from './services/carrera.service';
import { DepartamentoService } from './services/departamento.service';
import { TallerService } from './services/taller.service';
//Componentes
import { AppComponent } from './app.component';
import { NoticiasComponent } from './noticia/noticias/noticias.component';
import { InicioComponent } from './inicio/inicio.component';
import { InvestigacionComponent } from './investigacion/investigacion/investigacion.component';
import { ComisionesComponent } from './comisiones/comisiones.component';
import { VinculacionComponent } from './vinculacion/vinculacion/vinculacion.component';
import { DepartamentoComponent } from './departamento/departamento/departamento.component';
import { NoticiasMasComponent } from './noticia/noticias-mas/noticias-mas.component';
import { NoticiaDetalleComponent } from './noticia/noticia-detalle/noticia-detalle.component';
import { EventoDetalleComponent } from './evento/evento-detalle/evento-detalle.component';
import { EventoListadoComponent } from './evento/evento-listado/evento-listado.component';
import { NoticiaListadoComponent } from './noticia/noticia-listado/noticia-listado.component';
import { PregradoListadoComponent } from './carreras/pregrado-listado/pregrado-listado.component';
import { CarreraDetalleComponent } from './carreras/carrera-detalle/carrera-detalle.component';
import { PostgradoListadoComponent } from './carreras/postgrado-listado/postgrado-listado.component';
import { DepartamentoDetalleComponent } from './departamento/departamento-detalle/departamento-detalle.component';
import { FuncionarioListadoComponent } from './funcionario/funcionario-listado/funcionario-listado.component';
import { FuncionarioDetalleComponent } from './funcionario/funcionario-detalle/funcionario-detalle.component';
import { InvestigacionAreaComponent } from './investigacion/investigacion-area/investigacion-area.component';
import { InvestigacionDepartamentoComponent } from './investigacion/investigacion-departamento/investigacion-departamento.component';
import { FacultadHistoriaComponent } from './facultad/facultad-historia/facultad-historia.component';
import { FacultadMisionVisionComponent } from './facultad/facultad-mision-vision/facultad-mision-vision.component';
import { FacultadOrganigramaComponent } from './facultad/facultad-organigrama/facultad-organigrama.component';
import { AcademicoDetalleComponent } from './academico/academico-detalle/academico-detalle.component';
import { AcademicoListadoComponent } from './academico/academico-listado/academico-listado.component';
import { NoticiaBusquedaComponent } from './noticia/noticia-busqueda/noticia-busqueda.component';
import { EventoBusquedaComponent } from './evento/evento-busqueda/evento-busqueda.component';
import { DepartamentoPublicacionComponent } from './departamento/departamento-publicacion/departamento-publicacion.component';
import { FacultadDecanaturaComponent } from './facultad/facultad-decanatura/facultad-decanatura.component';
import { AcademicoDetalleEjemploComponent } from './academico/academico-detalle-ejemplo/academico-detalle-ejemplo.component';
import { PublicacionesTotalComponent } from './publicacion_info/publicaciones-total/publicaciones-total.component';
import { PublicacionComponent } from './publicacion_info/publicacion/publicacion.component';
import { FacultadRecursosComponent } from './facultad/facultad-recursos/facultad-recursos.component';
import { AcademicoAlumnoComponent } from './academico/academico-alumno/academico-alumno.component';
import { VinculacionMedioComponent } from './vinculacion/vinculacion-medio/vinculacion-medio.component';
import { NoticiaCarreraComponent } from './noticia/noticia-carrera/noticia-carrera.component';
import { NoticiaVinculacionComponent } from './noticia/noticia-vinculacion/noticia-vinculacion.component';
import { EventoVinculacionComponent } from './evento/evento-vinculacion/evento-vinculacion.component';
import { EventoCarreraComponent } from './evento/evento-carrera/evento-carrera.component';
import { GaleriaCarreraComponent } from './carreras/galeria-carrera/galeria-carrera.component';
import { FacultadRecursosEducacionComponent } from './facultad/facultad-recursos-educacion/facultad-recursos-educacion.component';
import { FacultadRecursosCoronaComponent } from './facultad/facultad-recursos-corona/facultad-recursos-corona.component';
import { FacultadRecursosEducacionMoodleComponent } from './facultad/facultad-recursos-educacion-moodle/facultad-recursos-educacion-moodle.component';
import { FacultadRecursosEducacionAdeccaComponent } from './facultad/facultad-recursos-educacion-adecca/facultad-recursos-educacion-adecca.component';
import { HexagonoBachilleratoComponent } from './hexagono/hexagono-bachillerato/hexagono-bachillerato.component';
import { HexagonoPregradoComponent } from './hexagono/hexagono-pregrado/hexagono-pregrado.component';
import { HexagonoDoctaradoComponent } from './hexagono/hexagono-doctarado/hexagono-doctarado.component';
import { HexagonoMagisterComponent } from './hexagono/hexagono-magister/hexagono-magister.component';
import { HexagonoAcademicoComponent } from './hexagono/hexagono-academico/hexagono-academico.component';
import { VinculacionVideoComponent } from './vinculacion/vinculacion-video/vinculacion-video.component';
import { VinculacionTallerComponent } from './vinculacion/vinculacion-taller/vinculacion-taller.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// registrar los locales con el nombre que quieras utilizar a la hora de proveer
registerLocaleData(localePy, 'es');
registerLocaleData(localePt, 'pt');
registerLocaleData(localeEn, 'en');

//calendar
import { FullCalendarModule } from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin
import interactionPlugin from '@fullcalendar/interaction';
import { PaginatePipe } from './pipes/paginate.pipe'; // a plugin
import { CustomMatPaginatorIntl } from './paginator-es';
import { FormularioComponent } from './formulario/formulario/formulario.component';
import { FormularioService } from './services/formulario.service';
import { CalendarioDetalleComponent } from './calendario-detalle/calendario-detalle.component';
import { FormularioFrameComponent } from './formulario/formulario-frame/formulario-frame.component';
import { InvestInicioComponent } from './investigacion/investigacion-maqueta/invest-inicio/invest-inicio.component';
//Vistas academicos 
import { InvestAcadPatriciaComponent } from './investigacion/investigacion-maqueta/cs_basicas/invest-acad-patricia/invest-acad-patricia.component';
import { InvestAcadGonzaloComponent } from './investigacion/investigacion-maqueta/cs_basicas/invest-acad-gonzalo/invest-acad-gonzalo.component';
import { InvestAcadCarlosComponent } from './investigacion/investigacion-maqueta/cs_basicas/invest-acad-carlos/invest-acad-carlos.component';
import { InvestAcadOscarComponent } from './investigacion/investigacion-maqueta/cs_basicas/invest-acad-oscar/invest-acad-oscar.component';
import { InvestAcadJuanComponent } from './investigacion/investigacion-maqueta/cs_basicas/invest-acad-juan/invest-acad-juan.component';
import { InvestAcadPatricioComponent } from './investigacion/investigacion-maqueta/cs_basicas/invest-acad-patricio/invest-acad-patricio.component';
import { InvestAcadEdgarComponent } from './investigacion/investigacion-maqueta/cs_basicas/invest-acad-edgar/invest-acad-edgar.component';
import { InvestAcadAndresComponent } from './investigacion/investigacion-maqueta/cs_basicas/invest-acad-andres/invest-acad-andres.component';
import { InvestAcadDanielaComponent } from './investigacion/investigacion-maqueta/cs_basicas/invest-acad-daniela/invest-acad-daniela.component';
import { InvestAcadCristianComponent } from './investigacion/investigacion-maqueta/cs_basicas/invest-acad-cristian/invest-acad-cristian.component';
import { InvestAcadEnriqueComponent } from './investigacion/investigacion-maqueta/cs_basicas/invest-acad-enrique/invest-acad-enrique.component';
//Vistas invetigacion fisica
import { InvestFisicaPublicComponent } from './investigacion/investigacion-maqueta/fisica/invest-fisica-public/invest-fisica-public.component';
import { InvestFisicaProyectFrComponent } from './investigacion/investigacion-maqueta/fisica/invest-fisica-proyect-fr/invest-fisica-proyect-fr.component';
import { InvestFisicaProyectExComponent } from './investigacion/investigacion-maqueta/fisica/invest-fisica-proyect-ex/invest-fisica-proyect-ex.component';
import { InvestFisicaProyectInComponent } from './investigacion/investigacion-maqueta/fisica/invest-fisica-proyect-in/invest-fisica-proyect-in.component';
import { InvestFisicaTesisMgComponent } from './investigacion/investigacion-maqueta/fisica/invest-fisica-tesis-mg/invest-fisica-tesis-mg.component';
import { InvestFisicaTesisDcComponent } from './investigacion/investigacion-maqueta/fisica/invest-fisica-tesis-dc/invest-fisica-tesis-dc.component';
import { Ci2030Component } from './ci2030/ci2030.component';
import { SeminariosComponent } from './seminarios/seminarios.component';
import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { SocialBarComponent } from './social-bar/social-bar.component';
import { AcademicosCarreraListadoComponent } from './carreras/academicos-carrera-listado/academicos-carrera-listado.component';
import { GaleriaInicioComponent } from './galeria/galeria-inicio/galeria-inicio.component';
import { GaleriaService } from './services/galeria.service';
import { GaleriaHistoricoComponent } from './galeria/galeria-historico/galeria-historico.component';
import { LineasInvestigacionComponent } from './carreras/lineas-investigacion/lineas-investigacion.component';

import { ListadoLaboratoriosComponent } from './carreras/listado-laboratorios/listado-laboratorios.component';
import { LaboratorioDetalleComponent } from './carreras/laboratorio-detalle/laboratorio-detalle.component';
import { AlumnosCarreraListadoComponent } from './carreras/alumnos-carrera-listado/alumnos-carrera-listado.component';
import { AlumnoDetalleComponent } from './carreras/alumno-detalle/alumno-detalle.component';
import { DirectorOpcionesCarreraComponent } from './carreras/director-opciones-carrera/director-opciones-carrera.component';
import { EventosCarreraDetalleComponent } from './carreras/eventos-carrera-detalle/eventos-carrera-detalle.component';
import { NoticiasCarreraDetalleComponent } from './carreras/noticias-carrera-detalle/noticias-carrera-detalle.component';
import { GruposInvestigacionComponent } from './investigacion/grupos-investigacion/grupos-investigacion.component';
import { CentrosInvestigacionComponent } from './investigacion/centros-investigacion/centros-investigacion.component';
import { ProyectosInvestigacionComponent } from './investigacion/proyectos-investigacion/proyectos-investigacion.component';

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  interactionPlugin
]);

@NgModule({
  declarations: [
    AppComponent,
    NoticiasComponent,
    InicioComponent,
    InvestigacionComponent,
    ComisionesComponent,
    VinculacionComponent,
    DepartamentoComponent,
    NoticiasMasComponent,
    NoticiaDetalleComponent,
    EventoDetalleComponent,
    EventoListadoComponent,
    NoticiaListadoComponent,
    PregradoListadoComponent,
    CarreraDetalleComponent,
    PostgradoListadoComponent,
    DepartamentoDetalleComponent,
    FuncionarioListadoComponent,
    FuncionarioDetalleComponent,
    InvestigacionAreaComponent,
    InvestigacionDepartamentoComponent,
    FacultadHistoriaComponent,
    FacultadMisionVisionComponent,
    FacultadOrganigramaComponent,
    AcademicoDetalleComponent,
    AcademicoListadoComponent,
    NoticiaBusquedaComponent,
    EventoBusquedaComponent,
    DepartamentoPublicacionComponent,
    FacultadDecanaturaComponent,
    AcademicoDetalleEjemploComponent,
    PublicacionesTotalComponent,
    PublicacionComponent,
    FacultadRecursosComponent,
    AcademicoAlumnoComponent,
    VinculacionMedioComponent,
    NoticiaCarreraComponent,
    NoticiaVinculacionComponent,
    EventoVinculacionComponent,
    EventoCarreraComponent,
    GaleriaCarreraComponent,
    FacultadRecursosEducacionComponent,
    FacultadRecursosCoronaComponent,
    FacultadRecursosEducacionMoodleComponent,
    FacultadRecursosEducacionAdeccaComponent,
    HexagonoBachilleratoComponent,
    HexagonoPregradoComponent,
    HexagonoDoctaradoComponent,
    HexagonoMagisterComponent,
    HexagonoAcademicoComponent,
    VinculacionVideoComponent,
    VinculacionTallerComponent,
    PaginatePipe,
    FormularioComponent,
    CalendarioDetalleComponent,
    FormularioFrameComponent,
    InvestInicioComponent,
    InvestAcadPatriciaComponent,
    InvestAcadGonzaloComponent,
    InvestAcadCarlosComponent,
    InvestAcadOscarComponent,
    InvestAcadJuanComponent,
    InvestAcadPatricioComponent,
    InvestAcadEdgarComponent,
    InvestAcadAndresComponent,
    InvestAcadDanielaComponent,
    InvestAcadCristianComponent,
    InvestAcadEnriqueComponent,
    InvestFisicaPublicComponent,
    InvestFisicaProyectFrComponent,
    InvestFisicaProyectExComponent,
    InvestFisicaProyectInComponent,
    InvestFisicaTesisMgComponent,
    InvestFisicaTesisDcComponent,
    Ci2030Component,
    SeminariosComponent,
    MenuComponent,
    FooterComponent,
    SocialBarComponent,
    AcademicosCarreraListadoComponent,
    GaleriaInicioComponent,
    GaleriaHistoricoComponent,
    LineasInvestigacionComponent,
    ListadoLaboratoriosComponent,
    LaboratorioDetalleComponent,
    AlumnosCarreraListadoComponent,
    AlumnoDetalleComponent,
    DirectorOpcionesCarreraComponent,
    EventosCarreraDetalleComponent,
    NoticiasCarreraDetalleComponent,
    GruposInvestigacionComponent,
    CentrosInvestigacionComponent,
    ProyectosInvestigacionComponent
  ],
  imports: [
    Routing,
    FormsModule,
    ReactiveFormsModule,
    FullCalendarModule, 
    // AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    A11yModule,
    PortalModule,
    ScrollingModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonToggleModule,
    MatStepperModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatPaginatorModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSortModule,
    MatTabsModule,
    MatTreeModule,
    ClipboardModule,
    DragDropModule,
    HttpClientModule,
    MatTooltipModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatChipsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatListModule,
    MatTableModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-Ar' },
    {provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl},
    ContenidoService,
    GlobalService,
    UsuarioService,
    SliderService,
    EventoService,
    NoticiaService,
    CarreraService,
    DepartamentoService,
    TallerService,
    FormularioService,
    GaleriaService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
