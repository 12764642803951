import { Component, OnInit } from '@angular/core';
import { ContenidoService } from '../../services/contenido.service';
import { GlobalService } from '../../services/global.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { SliderService } from '../../services/slider.service';
import { EventoService } from '../../services/evento.service';
import { NoticiaService } from '../../services/noticia.service';
import { CarreraService } from '../../services/carrera.service';
import { HexagonoService } from '../../services/hexagono.service';
import { DepartamentoService } from '../../services/departamento.service';
import { TranslateService } from '@ngx-translate/core';
import { UsuarioService } from '../../services/usuario.service';
import { TallerService } from '../../services/taller.service';
import { FormularioService } from '../../services/formulario.service';

declare var VanillaTilt;

@Component({
  selector: 'app-formulario-frame',
  templateUrl: './formulario-frame.component.html',
  styleUrls: ['./formulario-frame.component.css']
})
export class FormularioFrameComponent implements OnInit {

  public formulario = {
    cod_formulario_respuesta: 0,
    cod_formulario: 0,
    nombre: "",
    descripcion: "",
    fecha: "",
    listado_item: []
  }

  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public TextoTaller = "";

  public activeLang = 'es';

  public ListadoSlider: any;
  public ListadoHexagono: any;
  public ListadoTallerActual: any;
  public ListadoFormularioImagen: any;

  public InicioSlider = "";

  public Loading: boolean;
  public Hexag: boolean;

  public RutaHexag = "";
  public TextoHexag = "";

  constructor(private _Router: Router, private _ActivatedRoute: ActivatedRoute, private _ContenidoService: ContenidoService, private _GlobalService: GlobalService,
    private _SliderService: SliderService, private _EventoService: EventoService, private _NoticiaService: NoticiaService,
    private _HexagonoService: HexagonoService, private _DepartamentoService: DepartamentoService, private _CarreraService: CarreraService,
    private _TallerService: TallerService, private translate: TranslateService, private _UsuarioService: UsuarioService,
    private _FormularioService: FormularioService) {


    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);

    this.ListadoSlider = [];
    this.ListadoBanner = [];
    this.ListadoHexagono = [];
    this.GETSlider();
    this.GETBanner();
  }




  ngOnInit() {
    this._ActivatedRoute.paramMap.subscribe(params => {
      if (params.has("id")) {
        let cod = params.get("id");

        this._FormularioService.GetFormularioCompletoFormatoId(cod).subscribe(
          result => {
            if (result.status == 200) {
              let datos: any = (result.body);
              console.log('formulario', datos);
              if( datos != null){
                datos["img"] = this._GlobalService.UrlArchivos() + datos["img"];
                this.formulario = datos;
                for (let i = 0; i < 20; i++) {
                  let pnew = '<p class="display-5" align="justify" style="color: #293352;">';
                  this.formulario.descripcion = this.formulario.descripcion.replace('<p>', pnew);
                }
                console.log(this.formulario.descripcion);
                this.GetFormularioImagen(cod);
              }else{
                this._Router.navigate(['/']);
              }
            } else {
              console.log('result', result)
              this._GlobalService.ToastError();
            }
            // this.Loading = !this.Loading;
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log('An error occurred:', err.error.message);
              this._GlobalService.ToastError();
            }
            // this.Loading = !this.Loading;
          }
        );
      } else {
        this._Router.navigate(['/']);
      }
    });
  }

  public radioChange(event, campo) {
    campo.radio_opcion = event.cod_itemlistado;
  }

  public onChange(event, campo) {
    campo.cod_opcion = event.cod_itemlistado;
  }

  public toggle(event, item) {
    if (event == true) {
      item.valor = 1;
    } else {
      item.valor = 0;
    }
  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  //Rutas
  public Redireccionar(tipo, cod) {
    switch (tipo) {
      case 1:
        this._Router.navigate(['/departamento-detalle/' + cod]);
        break;
      case 2:
        this._Router.navigate(['/carrera-detalle/' + cod]);
        break;
      // facultad-historia
      case 3:
        sessionStorage.setItem("reload-facultad-historia", JSON.stringify(true));
        this._Router.navigate(['/facultad-historia']);
        break;
      // facultad-mision-vision        
      case 4:
        sessionStorage.setItem("reload-facultad-mision-vision", JSON.stringify(true));
        this._Router.navigate(['/facultad-mision-vision']);
        break;
      // facultad-organigrama        
      case 5:
        sessionStorage.setItem("reload-facultad-organigrama", JSON.stringify(true));
        this._Router.navigate(['/facultad-organigrama']);
        break;
      // vinculacion        
      case 6:
        sessionStorage.setItem("reload-vinculacion", JSON.stringify(true));
        this._Router.navigate(['/vinculacion']);
        break;
      // departamento        
      case 7:
        sessionStorage.setItem("reload-departamento", JSON.stringify(true));
        this._Router.navigate(['/departamento']);
        break;
      // pregrado-listado        
      case 8:
        sessionStorage.setItem("reload-pregrado-listado", JSON.stringify(true));
        this._Router.navigate(['/pregrado-listado']);
        break;
      // postgrado-listado        
      case 9:
        sessionStorage.setItem("reload-postgrado-listado", JSON.stringify(true));
        this._Router.navigate(['/postgrado-listado']);
        break;
      // investigacion        
      case 10:
        sessionStorage.setItem("reload-investigacion", JSON.stringify(true));
        this._Router.navigate(['/investigacion-inicio']);
        break;
      // recursos        
      case 11:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos']);
        break;
      // viculacion medio        
      case 12:
        sessionStorage.setItem("reload-vinculacion-medio", JSON.stringify(true));
        this._Router.navigate(['/vinculacion-medio']);
        break;
      // recursos educacion        
      case 13:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-educacion']);
        break;
      // recursos coronavirus       
      case 14:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-corona']);
        break;
      // seminarios       
      case 15:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/seminarios']);
        break;
    }
  }

  public GetFormularioImagen(cod) {
    this.Loading = !this.Loading;
    this._FormularioService.GetFormularioImagen(cod).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          datos.forEach(element => {
            element.base64 = this._GlobalService.UrlArchivos() + element.base64;
            console.log(element);
          });
          console.log('formulario Imagen', datos);

          this.ListadoFormularioImagen = datos;

        } else {
          console.log('result', result)
          this._GlobalService.ToastError();
        }
        this.Loading = !this.Loading;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
          this._GlobalService.ToastError();
        }
        this.Loading = !this.Loading;
      }
    );
  }

  public GETSlider() {
    this._SliderService.GETSlider().subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioSlider = element["img"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoSlider = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public RedirectBanner(url) {
    window.open(url);
  }

  public GETBanner() {
    this._SliderService.GETBannerId(2).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public PostFormularioRespuesta(Datos) {
    this.Loading = !this.Loading;
    this._FormularioService.PostFormularioRespuesta(Datos).subscribe(
      result => {
        this.Loading = !this.Loading;
        if (result.status == 200) {
          let info = result.body;
          console.log(info)
          if (info == true) {
            this._GlobalService.ToastOk();
            // this.GetFormularioRespuesta(this.formulario.cod_formulario);
          }
        } else {
          console.log('result', result)
          this._GlobalService.ToastError();
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
          this._GlobalService.ToastError();
        }
        this.Loading = !this.Loading;
      }
    );
  }

  public ValidacionesFormulario(): boolean {
    let validado = true;

    for (let element of this.formulario.listado_item) {
      if (element.validar == 1) {
        //  console.log(element);
        switch (element.tipo) {
          case "1":
            if (element.respuesta_texto.trim().length == 0) {
              validado = false;
              console.log(element.respuesta_texto);
            }
            break;
          case "4":
            if (element.cod_opcion == 0) {
              validado = false;
            }
            break;
          case "5":
            let cantidad_op = 0;

            for (let op of element.listado_opciones) {
              if (op.valor == 0) {
                cantidad_op = cantidad_op + 1;
              }
            }

            if (element.listado_opciones.length == cantidad_op) {
              validado = false;
            }
            break;
        }

        if (validado == false) {
          this._GlobalService.ToastInfo('Ingrese la información del campo "' + element.nombre + '"');
          break;
        }
      }
    }
    return validado;
  }

  public RegistrarRespuesta() {
    if (this.ValidacionesFormulario() == true) {
      console.log(JSON.stringify(this.formulario));
      this.PostFormularioRespuesta(this.formulario);
    }
  }
}
