import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';

@Injectable({
    providedIn: 'root'
})
export class SliderService {
    private url: string;
    constructor(private _HttpClient: HttpClient,
        private _GlobalService: GlobalService) {
        this.url = _GlobalService.UrlApi();
    }

    public GETSlider() {
        var urlFull = this.url + 'slider';
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

   public RedirectBanner(url){
    window.open(url);
  }

  public GETBanner() {
        var urlFull = this.url + 'banner';
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GETBannerId(Id) {
        var urlFull = this.url + 'banner/'+Id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }
}
