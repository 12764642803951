import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { GlobalService } from './global.service';
@Injectable()

export class GaleriaService {
    public url: string;
    constructor(
        private _GlobalService: GlobalService,
        private _HttpClient: HttpClient,
    ) {
        this.url = _GlobalService.UrlApi();
    }

    public GetImagenesGaleria() {
        var urlFull = this.url + 'imagenesgaleria';
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public PostImagenesGaleria(Datos) {
        var urlFull = this.url + 'imagenesgaleria';
        console.log(urlFull);
        return this._HttpClient.post(urlFull, Datos, { observe: 'response' });
    }

}
