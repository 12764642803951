import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';

@Injectable({
    providedIn: 'root'
})
export class GraphService {
    private url: string;
    constructor(private _HttpClient: HttpClient,
        private _GlobalService: GlobalService) {
        this.url = "https://ciencias.ubiobio.cl/web/graphql_ciencias/";
    }


    public QueryBrief(cod_recurso) {
        var data = JSON.stringify({
            query: `
        {
            brief(cod_brief:${cod_recurso}) {
            descripcion
            }
        }`});

        return this._HttpClient.post(this.url, data, { observe: 'response' });
    }

    public QueryAutoridades() {
        var data = JSON.stringify({
            query: `
        {
            autoridades {
                cod_autoridad
                nombre
                contacto
                cargo
                img
                tipo
            }
        }`});

        return this._HttpClient.post(this.url, data, { observe: 'response' });
    }

    public QueryRedesSociales() {
        var data = JSON.stringify({
            query: `
        {
            redes_sociales {
                cod_redes
                red_social
                descripcion
            }
        }`});

        return this._HttpClient.post(this.url, data, { observe: 'response' });
    }

    public QueryRecursoCorporativo() {
        var data = JSON.stringify({
            query: `
            {
                recursos_corporativos {
                  cod_recurso
                  titulo
                  descripcion,
                  documentos {
                    cod_documento
                    cod_recurso
                    img
                    documento
                  }
                } 
              }`});

        return this._HttpClient.post(this.url, data, { observe: 'response' });
    }

    public QueryArancelCarrera(cod_carrera) {
        var data = JSON.stringify({
            query: `
            {
                carrera_arancel(cod_carrera:${cod_carrera}) {
                    cod_arancel
                    cod_carrera
                    anual
                    mensual
                }
              }`});

        return this._HttpClient.post(this.url, data, { observe: 'response' });
    }




}
