import { Component, Input, OnInit } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { GlobalService } from "../../services/global.service";
import { CarreraService } from "../../services/carrera.service";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SliderService } from "../../services/slider.service";
import { NoticiaService } from "../../services/noticia.service";
import { EventoService } from "../../services/evento.service";

@Component({
  selector: 'app-director-opciones-carrera',
  templateUrl: './director-opciones-carrera.component.html',
  styleUrls: ['./director-opciones-carrera.component.css']
})
export class DirectorOpcionesCarreraComponent implements OnInit {

  public carrera = {
    cod_carrera: 0,
    nombre: "",
    nombre_ingles: "",
    img: "",
    tipo: 1,
    web_oficial: "",
    face: "",
    insta: "",
    logo: "",
    base64_logo: "",
    cambio_logo: 0,
    base64: "",
    cambio: 0,
  };

  public carrera_contacto_director = {
    cod_contacto: 0,
    cod_carrera: 0,
    nombre: "",
    cargo: "",
    sede: "",
    correo: "",
    ubicacion: "",
    fono: "",
    img: "",
    base64: "",
    cambio: 0
  };

  public carrera_detalle = {
    cod_detalle: 0,
    cod_carrera: 0,
    descripcion_inicial: "",
    descripcion_inicial_ingles: "",
    ficha_carrera_pdf: "",
    ficha_carrera_pdf_base64: "",
    ficha_carrera_pdf_cambio: 0,
    malla_curricular_pdf: "",
    malla_curricular_pdf_base64: "",
    malla_curricular_pdf_cambio: 0,
    nem: 0,
    ranking: 0,
    lenguaje: 0,
    matematica: 0,
    matematica2: 0,
    historia_geografia: 0,
    ciencias: 0,
    cuota_basica: 0,
    arancel: 0,
    descripcion: "",
    descripcion_ingles: "",
    perfil_egreso: "",
    perfil_egreso_ingles: "",
    malla_curricular: "",
    malla_curricular_base64: "",
    malla_curricular_cambio: 0,
  };

  public funcionalidades = {
    cod_carrera: 0,
    linea_investigacion: 0,
    academicos: 0,
    funcionarios: 0,
    galeria: 0,
    video: 0,
    eventos_propios: 0,
    noticias_propias: 0,
    laboratorios: 0,
    alumnos: 0,
    salidas_terreno: 0,
  };


  public linea_investigacion = false;
  public academicos = false;
  public funcionarios = false;
  public galeria = false;
  public video = false;
  public eventos_propios = false;
  public noticias_propias = false;
  public laboratorios = false;
  public alumnos = false;
  public salidas_terreno = false;

  public Loading: boolean;

  @Input() cod_carrera;
  @Input() tipo;

  constructor(
    private _CarreraService: CarreraService,
    private _GlobalService: GlobalService,
  ) { }

  ngOnInit(): void {
    console.log("cod_carrera", this.cod_carrera);
    console.log("tipo", this.tipo);
    this._CarreraService.GETCarreraID(this.cod_carrera).subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          this.carrera = datos;
          this.GetFuncionalidadesCarrera();
          this.GetCarreraContactoDirector();
          this.GetCarreraDetalle();
        } else {
          console.log("result", result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        }
      }
    );

  }

  public RedirectPag(url) {
    window.open(url);
  }

  public AbrirDocumento(tipo) {
    let url = "";
    switch (tipo) {
      case 1:
        url = this.carrera_detalle.ficha_carrera_pdf_base64;
        break;
      case 2:
        url = this.carrera_detalle.malla_curricular_pdf_base64;
        break;
    }
    window.open(url);
  }

  public GetFuncionalidadesCarrera() {
    this._CarreraService.GetFuncionalidadesCarrera(this.cod_carrera).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          this.funcionalidades = datos[0];
          this.SelectCheck();
        } else {
          console.log('result', result)
          this._GlobalService.ToastError();
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
          this._GlobalService.ToastError();
        }
      }
    );
  }

  public SelectCheck() {
    if (this.funcionalidades.linea_investigacion == 1) {
      this.linea_investigacion = true;
    }
    if (this.funcionalidades.academicos == 1) {
      this.academicos = true;
    }
    if (this.funcionalidades.funcionarios == 1) {
      this.funcionarios = true;
    }
    if (this.funcionalidades.galeria == 1) {
      this.galeria = true;
    }
    if (this.funcionalidades.video == 1) {
      this.video = true;
    }
    if (this.funcionalidades.eventos_propios == 1) {
      this.eventos_propios = true;
    }
    if (this.funcionalidades.noticias_propias == 1) {
      this.noticias_propias = true;
    }
    if (this.funcionalidades.laboratorios == 1) {
      this.laboratorios = true;
    }
    if (this.funcionalidades.alumnos == 1) {
      this.alumnos = true;
    }
    if (this.funcionalidades.salidas_terreno == 1) {
      this.salidas_terreno = true;
    }
  }

  public GetCarreraContactoDirector() {
    this.Loading = !this.Loading;
    this._CarreraService.GetCarreraContactoDirector(this.cod_carrera).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          this.carrera_contacto_director = datos;
          this.carrera_contacto_director.base64 = this._GlobalService.UrlArchivos() + this.carrera_contacto_director.base64;
          this.carrera_contacto_director.cod_carrera = this.cod_carrera;

          console.log('Carrera Contacto', JSON.stringify(datos));

        } else {
          console.log('result', result)
        }
        this.Loading = !this.Loading;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
        this.Loading = !this.Loading;
      }
    );
  }

  public GetCarreraDetalle() {
    this.Loading = !this.Loading;
    this._CarreraService.GetCarreraDetalle(this.cod_carrera).subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          this.carrera_detalle = datos;
          this.carrera_detalle.ficha_carrera_pdf_base64 =
            this._GlobalService.UrlArchivos() +
            this.carrera_detalle.ficha_carrera_pdf_base64;
          this.carrera_detalle.malla_curricular_pdf_base64 =
            this._GlobalService.UrlArchivos() +
            this.carrera_detalle.malla_curricular_pdf_base64;
          this.carrera_detalle.malla_curricular_base64 =
            this._GlobalService.UrlArchivos() +
            this.carrera_detalle.malla_curricular_base64;
          this.carrera_detalle.cod_carrera = this.cod_carrera;

          // console.log('Carrera Detalle', JSON.stringify(datos));
        } else {
          console.log("result", result);
        }
        this.Loading = !this.Loading;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        }
        this.Loading = !this.Loading;
      }
    );
  }

}
