import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { CarreraService } from '../../services/carrera.service';
import { GlobalService } from '../../services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { DepartamentoService } from '../../services/departamento.service';
import { SliderService } from '../../services/slider.service';
declare var VanillaTilt;


@Component({
  selector: 'app-facultad-decanatura',
  templateUrl: './facultad-decanatura.component.html',
  styleUrls: ['./facultad-decanatura.component.css']
})
export class FacultadDecanaturaComponent implements OnInit {

  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public activeLang = 'es';

  public curriculum = {
    general: "general",
    publicacion: "publicacion",
    investigacion: "investigacion",
    ensenaza: "enseñanza",
    biografia: "biografia"
  }

  public general: boolean;
  public publicacion: boolean;
  public investigacion: boolean;
  public ensenaza: boolean;
  public biografia: boolean;
  public supervision: boolean;

  public texto_principal: string;

  constructor(private _Router: Router, private _GlobalService: GlobalService,private _SliderService:SliderService, private _DepartamentoService: DepartamentoService,
    private _CarreraService: CarreraService, private translate: TranslateService) {
    this.reload();

    //info
    this.general = true;
    this.publicacion = false;
    this.investigacion = false;
    this.ensenaza = false;
    this.biografia = false;
    //
    this.texto_principal = this.curriculum.general;
    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);
    this.ListadoBanner = [];
    this.GETBanner();
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-facultad-decanatura"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-facultad-decanatura", JSON.stringify(false));
      window.location.reload();
    }
  }

 public RedirectBanner(url){
    window.open(url);
  }

  public GETBanner() {
    this._SliderService.GETBannerId(2).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  ngOnInit() {

    VanillaTilt.init(document.querySelector(".tilt-image"), { max: 25, speed: 400 });

    VanillaTilt.init(document.querySelectorAll(".tilt-image"));
  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  //Rutas
  public Redireccionar(tipo, cod) {
    switch (tipo) {
      case 1:
        this._Router.navigate(['/departamento-detalle/'+cod]);
        break;
      case 2:
        this._Router.navigate(['/carrera-detalle/'+cod]);
        break;
      // facultad-historia
      case 3:
        sessionStorage.setItem("reload-facultad-historia", JSON.stringify(true));
        this._Router.navigate(['/facultad-historia']);
        break;
      // facultad-mision-vision        
      case 4:
        sessionStorage.setItem("reload-facultad-mision-vision", JSON.stringify(true));
        this._Router.navigate(['/facultad-mision-vision']);
        break;
      // facultad-organigrama        
      case 5:
        sessionStorage.setItem("reload-facultad-organigrama", JSON.stringify(true));
        this._Router.navigate(['/facultad-organigrama']);
        break;
      // vinculacion        
      case 6:
        sessionStorage.setItem("reload-vinculacion", JSON.stringify(true));
        this._Router.navigate(['/vinculacion']);
        break;
      // departamento        
      case 7:
        sessionStorage.setItem("reload-departamento", JSON.stringify(true));
        this._Router.navigate(['/departamento']);
        break;
      // pregrado-listado        
      case 8:
        sessionStorage.setItem("reload-pregrado-listado", JSON.stringify(true));
        this._Router.navigate(['/pregrado-listado']);
        break;
     // postgrado-listado        
      case 9:
        sessionStorage.setItem("reload-postgrado-listado", JSON.stringify(true));
        this._Router.navigate(['/postgrado-listado']);
        break;
      // investigacion        
      case 10:
        sessionStorage.setItem("reload-investigacion", JSON.stringify(true));
        this._Router.navigate(['/investigacion-inicio']);
        break;
    // recursos        
      case 11:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos']);
        break;
      // viculacion medio        
    case 12:
        sessionStorage.setItem("reload-vinculacion-medio", JSON.stringify(true));
        this._Router.navigate(['/vinculacion-medio']);
        break;
      // recursos educacion        
      case 13:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-educacion']);
        break;
      // recursos coronavirus       
      case 14:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-corona']);
        break;
      // seminarios       
      case 15:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/seminarios']);
        break;
    }
  }

  public InfoCurriculum(tipo) {
    switch (tipo) {
      case 1:
        this.general = true;
        this.publicacion = false;
        this.investigacion = false;
        this.ensenaza = false;
        this.biografia = false;
        this.supervision = false;
        break;
      case 2:
        this.general = false;
        this.publicacion = true;
        this.investigacion = false;
        this.ensenaza = false;
        this.biografia = false;
        this.supervision = false;
        break;
      case 3:
        this.general = false;
        this.publicacion = false;
        this.investigacion = true;
        this.ensenaza = false;
        this.biografia = false;
        this.supervision = false;
        break;
      case 4:
        this.general = false;
        this.publicacion = false;
        this.investigacion = false;
        this.ensenaza = true;
        this.biografia = false;
        this.supervision = false;
        break;
      case 5:
        this.general = false;
        this.publicacion = false;
        this.investigacion = false;
        this.ensenaza = false;
        this.biografia = true;
        this.supervision = false;
        break;
      case 6:
        this.general = false;
        this.publicacion = false;
        this.investigacion = false;
        this.ensenaza = false;
        this.biografia = false;
        this.supervision = true;
        break;
    }
  }


}
