import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { GraphService } from '../services/graph.service';

@Component({
  selector: 'app-social-bar',
  templateUrl: './social-bar.component.html',
  styleUrls: ['./social-bar.component.css']
})
export class SocialBarComponent implements OnInit {

  public redes_sociales = {
    instagram: {},
    facebook: {},
    youtube: {},
    twitter: {},
    linkedin: {}
  }

  constructor(private _GraphService: GraphService) { }

  ngOnInit(): void {
    this.QueryRedesSociales();
  }

  public QueryRedesSociales() {
    this._GraphService.QueryRedesSociales().subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);

          console.log("redes sociales", datos.data.redes_sociales);
      
          datos.data.redes_sociales.forEach(element => {
            switch (element.red_social) {
              case 'instagram':
                this.redes_sociales.instagram = element;
                break;
              case 'facebook':
                this.redes_sociales.facebook = element;
                break;
              case 'youtube':
                this.redes_sociales.youtube = element;
                break;
              case 'twitter':
                this.redes_sociales.twitter = element;
                break;
              case 'linkedin':
                this.redes_sociales.linkedin = element;
                break;              
            }
          });
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

}
