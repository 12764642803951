import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { CarreraService } from '../../services/carrera.service';
import { TranslateService } from '@ngx-translate/core';
import { DepartamentoService } from '../../services/departamento.service';
import { GlobalService } from '../../services/global.service';
import { SliderService } from '../../services/slider.service';

@Component({
  selector: 'app-departamento-publicacion',
  templateUrl: './departamento-publicacion.component.html',
  styleUrls: ['./departamento-publicacion.component.css']
})
export class DepartamentoPublicacionComponent implements OnInit {

  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public activeLang = 'es';
  public departamento = {
    cod_departamento: 0,
    nombre: "",
    img: "",
    descripcion: "",
    web_oficial: "",
    tipo: 0,
    base64: "",
    cambio: 0
  };
  public ListadoPublicaciones: any;
  public Loading: boolean;

  constructor(private _Router: Router, private _ActivatedRoute: ActivatedRoute,private _SliderService:SliderService, private _DepartamentoService: DepartamentoService, private _GlobalService: GlobalService,
    private _CarreraService: CarreraService, private translate: TranslateService) {
    this.reload();

    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);
    let info = JSON.parse(sessionStorage.getItem("departamento_detalle"));
    this.departamento = info
   // this.Loading = !this.Loading;
    // let timer = setInterval(() => {
    //   this.Loading = !this.Loading;
    //   clearInterval(timer);
    // }, 1500);

    this.GETDepartamentoPublicacionID();
    this.ListadoBanner = [];
    this.GETBanner();
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-departamento-publicacion"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-departamento-publicacion", JSON.stringify(false));
      window.location.reload();
    }
  }

 public RedirectBanner(url){
    window.open(url);
  }

  public GETBanner() {
    this._SliderService.GETBannerId(2).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  ngOnInit() {
    this._ActivatedRoute.paramMap.subscribe(params => {
      if (params.has("id")) {
        let cod = params.get("id");
        this._DepartamentoService.GETDepartamentoPublicacionID(cod).subscribe(
          result => {
            if (result.status == 200) {
              let datos: any = (result.body);
              this.ListadoPublicaciones = datos;
              console.log(datos);
            } else {
              console.log('result', result);
            }
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log('An error occurred:', err.error.message);
            }
          }
        );
      } else {
        this._Router.navigate(['/']);
      }
    });
  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  //Rutas
  public Redireccionar(tipo, cod) {
    switch (tipo) {
      case 1:
        this._Router.navigate(['/departamento-detalle/'+cod]);
        break;
      case 2:
        this._Router.navigate(['/carrera-detalle/'+cod]);
        break;
      // facultad-historia
      case 3:
        sessionStorage.setItem("reload-facultad-historia", JSON.stringify(true));
        this._Router.navigate(['/facultad-historia']);
        break;
      // facultad-mision-vision        
      case 4:
        sessionStorage.setItem("reload-facultad-mision-vision", JSON.stringify(true));
        this._Router.navigate(['/facultad-mision-vision']);
        break;
      // facultad-organigrama        
      case 5:
        sessionStorage.setItem("reload-facultad-organigrama", JSON.stringify(true));
        this._Router.navigate(['/facultad-organigrama']);
        break;
      // vinculacion        
      case 6:
        sessionStorage.setItem("reload-vinculacion", JSON.stringify(true));
        this._Router.navigate(['/vinculacion']);
        break;
      // departamento        
      case 7:
        sessionStorage.setItem("reload-departamento", JSON.stringify(true));
        this._Router.navigate(['/departamento']);
        break;
      // pregrado-listado        
      case 8:
        sessionStorage.setItem("reload-pregrado-listado", JSON.stringify(true));
        this._Router.navigate(['/pregrado-listado']);
        break;
     // postgrado-listado        
      case 9:
        sessionStorage.setItem("reload-postgrado-listado", JSON.stringify(true));
        this._Router.navigate(['/postgrado-listado']);
        break;
      // investigacion        
      case 10:
        sessionStorage.setItem("reload-investigacion", JSON.stringify(true));
        this._Router.navigate(['/investigacion-inicio']);
        break;
    // recursos        
      case 11:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos']);
        break;
      // viculacion medio        
    case 12:
        sessionStorage.setItem("reload-vinculacion-medio", JSON.stringify(true));
        this._Router.navigate(['/vinculacion-medio']);
        break;
      // recursos educacion        
      case 13:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-educacion']);
        break;
      // recursos coronavirus       
      case 14:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-corona']);
        break;
      // seminarios       
      case 15:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/seminarios']);
        break;
    }
  }

  Depto(departamento) {
    let url = "";
    switch (departamento) {
      case 1:
        url = "http://www.cienciasbasicas.cl/"
        break;
      case 2:
        url = "http://estadistica.ubiobio.cl/web2/"
        break;
      case 3:
        url = "http://ciencias.ubiobio.cl/fisica/wiki/"
        break;
      case 4:
        url = "http://ciencias.ubiobio.cl/quimica/web/"
        break;
      case 5:
        url = "http://dmat.ciencias.ubiobio.cl/"
        break;
    }
    window.open(url);
  }


  public GETDepartamentoPublicacionID() {
    this._DepartamentoService.GETDepartamentoPublicacionID(this.departamento.cod_departamento).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          this.ListadoPublicaciones = datos;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public DetalleDepartamento(departamento) {
    sessionStorage.setItem("reload-departamento_detalle", JSON.stringify(true));
    sessionStorage.setItem("departamento_detalle", JSON.stringify(departamento));
    console.log(departamento);
    this._Router.navigate(['/departamento-detalle']);
  }

}
