import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../../services/global.service';
import { DepartamentoService } from '../../services/departamento.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CarreraService } from '../../services/carrera.service';
import { SliderService } from '../../services/slider.service';
import { NoticiaService } from '../../services/noticia.service';
import { UsuarioService } from '../../services/usuario.service';
import { CalendarOptions } from '@fullcalendar/angular';
import esLocale from '@fullcalendar/core/locales/es';
import { MatDialog } from '@angular/material/dialog';
import { CalendarioDetalleComponent } from '../../calendario-detalle/calendario-detalle.component';
declare var VanillaTilt;

@Component({
  selector: 'app-vinculacion-medio',
  templateUrl: './vinculacion-medio.component.html',
  styleUrls: ['./vinculacion-medio.component.css']
})
export class VinculacionMedioComponent implements OnInit {

  calendarOptions: CalendarOptions = {
    initialView: 'dayGridMonth',
    dateClick: this.handleDateClick.bind(this), // bind is important!
    locale: esLocale
  };

  events = [
    { title: 'Taller Vocacional Ingeniería estadística', date: '2020-08-28' ,img:false, base64:"" },
    { title: 'SCI TALKS', date: '2020-08-27',img:false, base64:""  },
    { title: 'Charla Terapia Génica', date: '2020-09-02',img:false, base64:""  }, 
    { title: 'Taller Vocacional Ingeniería en recursos naturales', date: '2020-09-04' ,img:false, base64:"" },
    { title: 'Taller Vocacional Bachillerato Concepción y Chillán a las 18hrs', date: '2020-11-06' ,img:true, base64:"assets/imgs/recursos/noviembre_6.jpeg" }
  ]

  // 
  public imgPrincipal = "assets/imgs/vinculacion/vinculacion-medio/slide.jpg";
  public ListadoBanner: any;
  public ListadoActividades: any;
  public InicioBanner = "";
  public URLBanner = "";
  public activeLang = 'es';

  public departamento = {
    cod_departamento: 0,
    nombre: "",
    nombre_ingles: "",
    img: "",
    descripcion: "",
    descripcion_ingles: "",
    web_oficial: "",
    tipo: 0,
    base64: "",
    cambio: 0
  };

  public departamento_contacto = {
    cod_contacto: 0,
    cod_departamento: 0,
    nombre: "",
    cargo: "",
    sede: "",
    correo: "",
    ubicacion: "",
    fono: "",
    img: "",
    base64: "",
    cambio: 0
  };

  public Galeria = {
    galeria1: {},
    galeria2: {},
    galeria3: {},
    galeria4: {},
    galeria5: {},
    galeria6: {},
  };

  public ListadoNoticias: any;
  public Loading: boolean;

  constructor(private _Router: Router, private _ActivatedRoute: ActivatedRoute, public dialog: MatDialog, private _SliderService: SliderService, private _DepartamentoService: DepartamentoService, private _GlobalService: GlobalService,
    private _CarreraService: CarreraService, private _UsuarioService: UsuarioService, private _NoticiaService: NoticiaService, private translate: TranslateService) {
    // this.reload();
    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);
    this.ListadoBanner = [];
    this.GETBanner();
    this.GETNoticia();
    this.GETActividadesTipo();
  }

  public handleDateClick(arg) {

    let ev = this.events.filter((e: any) =>
      e.date == arg.dateStr);
    // arg.dateStr
    console.log("eventos", ev);

    this.DetalleCalendario(ev);
  }


  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-departamento-detalle"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-departamento-detalle", JSON.stringify(false));
      window.location.reload();
    }
  }

  ngOnInit() {
    VanillaTilt.init(document.querySelector(".tilt-image"), { max: 25, speed: 400 });
    VanillaTilt.init(document.querySelectorAll(".tilt-image"));
  }


  DetalleCalendario(detalle_calendario) {
    const dialogRef = this.dialog.open(CalendarioDetalleComponent,
      {
        width: '500px',
        data: { detalle_calendario: detalle_calendario, cambio: false }
      });

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {

      } else {
        let form = result.detalle_calendario;
        console.log(form);
      }
    });
  }

  public RedirectBanner(url) {
    window.open(url);
  }

  public GETActividadesTipo() {
    this._UsuarioService.GETActividadesTipo().subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);

          this.ListadoActividades = datos;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public GETBanner() {
    this._SliderService.GETBannerId(5).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public GETNoticia() {
    this._NoticiaService.GETNoticiaVistaId(3).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          console.log("noticias", datos);

          this.calendarOptions = {
            initialView: 'dayGridMonth',
            dateClick: this.handleDateClick.bind(this), // bind is important!
            locale: esLocale,
            events: [
              { title: 'Taller Vocacional Ingeniería estadística', date: '2020-08-28' },
              { title: 'SCI TALKS', date: '2020-08-27' },
              { title: 'Charla Terapia Génica', date: '2020-09-02' },
              { title: 'Taller Vocacional Ingeniería en recursos naturales', date: '2020-09-04' },
              { title: 'Taller Vocacional Bachillerato Concepción y Chillán a las 18hrs', date: '2020-11-06' }

            ]
          };

          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
          });
          // let listado = [];
          // for (let i = 0; i < 6; i++) {

          //   listado.push(datos[i]);

          // }
          this.ListadoNoticias = datos;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }


  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  //Rutas
  public Redireccionar(tipo, cod) {
    switch (tipo) {
      // departamento detalle
      case 1:
        this._Router.navigate(['/departamento-detalle/' + cod]);
        break;
      // Carrera detalle
      case 2:
        this._Router.navigate(['/carrera-detalle/' + cod]);
        break;
      // facultad-historia
      case 3:
        sessionStorage.setItem("reload-facultad-historia", JSON.stringify(true));
        this._Router.navigate(['/facultad-historia']);
        break;
      // facultad-mision-vision        
      case 4:
        sessionStorage.setItem("reload-facultad-mision-vision", JSON.stringify(true));
        this._Router.navigate(['/facultad-mision-vision']);
        break;
      // facultad-organigrama        
      case 5:
        sessionStorage.setItem("reload-facultad-organigrama", JSON.stringify(true));
        this._Router.navigate(['/facultad-organigrama']);
        break;
      // vinculacion        
      case 6:
        sessionStorage.setItem("reload-vinculacion", JSON.stringify(true));
        this._Router.navigate(['/vinculacion']);
        break;
      // departamento        
      case 7:
        sessionStorage.setItem("reload-departamento", JSON.stringify(true));
        this._Router.navigate(['/departamento']);
        break;
      // pregrado-listado        
      case 8:
        sessionStorage.setItem("reload-pregrado-listado", JSON.stringify(true));
        this._Router.navigate(['/pregrado-listado']);
        break;
      // postgrado-listado        
      case 9:
        sessionStorage.setItem("reload-postgrado-listado", JSON.stringify(true));
        this._Router.navigate(['/postgrado-listado']);
        break;
      // investigacion        
      case 10:
        sessionStorage.setItem("reload-investigacion", JSON.stringify(true));
        this._Router.navigate(['/investigacion-inicio']);
        break;
      // recursos        
      case 11:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos']);
        break;
      // viculacion medio        
      case 12:
        sessionStorage.setItem("reload-vinculacion-medio", JSON.stringify(true));
        this._Router.navigate(['/vinculacion-medio']);
        break;
      // recursos educacion        
      case 13:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-educacion']);
        break;
      // recursos coronavirus       
      case 14:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-corona']);
        break;
      // seminarios       
      case 15:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/seminarios']);
        break;
    }
  }

  public ListadoVideos(actividad) {
    console.log(actividad);
    this._Router.navigate(['/vinculacion-video/' + actividad.cod_tipo]);
  }

}
