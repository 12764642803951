import { Component, Input, OnInit } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { GlobalService } from "../../services/global.service";
import { CarreraService } from "../../services/carrera.service";
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SliderService } from "../../services/slider.service";
import { NoticiaService } from "../../services/noticia.service";
import { EventoService } from "../../services/evento.service";

@Component({
  selector: 'app-noticias-carrera-detalle',
  templateUrl: './noticias-carrera-detalle.component.html',
  styleUrls: ['./noticias-carrera-detalle.component.css']
})
export class NoticiasCarreraDetalleComponent implements OnInit {

  @Input() cod_carrera;
  @Input() tipo;


  public ListadoNoticias: any;
  public ifListadoNoticias = false;

  constructor(
    private _GlobalService: GlobalService,
    private _NoticiaService: NoticiaService,
  ) { }

  ngOnInit(): void {
    console.log("cod_carrera",this.cod_carrera);
    console.log("tipo",this.tipo);
    this.GETNoticia();
  }

  public GETNoticia() {
    this._NoticiaService.GetCarreraNoticias(this.cod_carrera).subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          let length = 0 ;
          datos.forEach((element) => {
            length++;
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
          });
          let listado = [];

          if(length > 0){
            this.ifListadoNoticias = true;
          }

          if (length <= 3) {
            for (let i = 0; i < length; i++) {
              listado.push(datos[i]);
            }
          } else {
            for (let i = 0; i < 3; i++) {
              listado.push(datos[i]);
            }
          }

          // this.OrdenarNoticias(datos);
          this.ListadoNoticias = listado;
          sessionStorage.setItem("noticia_listado", JSON.stringify(datos));
          console.log(datos);
        } else {
          console.log("result", result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        }
      }
    );
  }

}
