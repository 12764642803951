import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GaleriaService } from 'src/app/services/galeria.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-galeria-historico',
  templateUrl: './galeria-historico.component.html',
  styleUrls: ['./galeria-historico.component.css']
})
export class GaleriaHistoricoComponent implements OnInit {

  public galeria: any;

  constructor(private _GlobalService: GlobalService, private _GaleriaService: GaleriaService, private _Router: Router) { 
    this.GetImagenesGaleria();
  }

  ngOnInit(): void {
  }

  public GetImagenesGaleria() {
    this._GaleriaService.GetImagenesGaleria().subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          datos.forEach(element => {
            element.base64 = this._GlobalService.UrlArchivos() +"galeria/" + element.img;
          });

          console.log('galeria de imagenes', datos);
          this.galeria = datos;

        } else {
          console.log('result', result)
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
          this._GlobalService.ToastError();
        }
      }
    );
  }

}
