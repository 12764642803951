import { Component, OnInit } from '@angular/core';
import { ContenidoService } from '../../services/contenido.service';
import { GlobalService } from '../../services/global.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { SliderService } from '../../services/slider.service';
import { EventoService } from '../../services/evento.service';
import { NoticiaService } from '../../services/noticia.service';
import { CarreraService } from '../../services/carrera.service';
import { HexagonoService } from '../../services/hexagono.service';
import { DepartamentoService } from '../../services/departamento.service';
import { TranslateService } from '@ngx-translate/core';
import { UsuarioService } from '../../services/usuario.service';
import { TallerService } from '../../services/taller.service';

// import * as $ from 'jquery';
declare var VanillaTilt;

@Component({
  selector: 'app-vinculacion-taller',
  templateUrl: './vinculacion-taller.component.html',
  styleUrls: ['./vinculacion-taller.component.css']
})
export class VinculacionTallerComponent implements OnInit {


  public inscripcion = {
    instagram: "",
    colegio: "",
    celular: "",
    ciudad: "",
    cod_carrera: 0,
    cod_ins: 0,
    cod_taller: 0,
    correo: "",
    curso: "",
    fecha_nacimiento: "",
    nombre: "",
    rut: ""
  };

  public fecha_nacimiento = new Date();

  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public TextoTaller = "";

  public activeLang = 'es';

  public ListadoNoticias: any;
  public ListadoSlider: any;
  public ListadoEventos: any;
  public ListadoHexagono: any;
  public ListadoTallerActual: any;

  public carreras: any;

  public InicioSlider = "";

  public Loading: boolean;
  public Hexag: boolean;

  public RutaHexag = "";
  public TextoHexag = "";

  public Suscripcion = {
    correo: ""
  };

  constructor(private _Router: Router, private _ContenidoService: ContenidoService, private _GlobalService: GlobalService,
    private _SliderService: SliderService, private _EventoService: EventoService, private _NoticiaService: NoticiaService,
    private _HexagonoService: HexagonoService, private _DepartamentoService: DepartamentoService, private _CarreraService: CarreraService,
    private _TallerService: TallerService, private translate: TranslateService, private _UsuarioService: UsuarioService) {
    this.reload();

    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);

    // this.Loading = !this.Loading;
    // let timer = setInterval(() => {
    //   this.Loading = !this.Loading;
    //   clearInterval(timer);
    // }, 1500);

    this.ListadoNoticias = [];
    this.ListadoEventos = [];
    this.ListadoSlider = [];
    this.ListadoBanner = [];
    this.ListadoHexagono = [];
    this.GETSlider();
    this.GETBanner();
    this.GETCarrera();
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-inicio"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-inicio", JSON.stringify(false));
      window.location.reload();
    }
  }

  public InfoTaller(taller) {
    this.TextoTaller = taller.descripcion;
    console.log(taller);
  }


  ngOnInit() {

  }

  public PostTallerIncripcion(Datos, cod) {
    Datos.cod_taller = cod;
    this.Loading = !this.Loading;
    this._TallerService.PostTallerIncripcion(Datos).subscribe(
      result => {
        this.Loading = !this.Loading;
        if (result.status == 200) {
          let info = result.body;
          console.log(info)
          if (info == true) {
            this._GlobalService.ToastOk();
          }
        } else {
          console.log('result', result)
          this._GlobalService.ToastError();
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
          this._GlobalService.ToastError();
        }
        this.Loading = !this.Loading;
      }
    );
  }

  public Validaciones(): boolean {
    let validado = true;
    if (!this.inscripcion.nombre) {
      validado = false;
      this._GlobalService.ToastInfo('Ingresé su nombre');
    } else if (!this.inscripcion.rut) {
      validado = false;
      this._GlobalService.ToastInfo('Ingresé su rut');
    } else if (!this.inscripcion.ciudad) {
      validado = false;
      this._GlobalService.ToastInfo('Ingresé su ciudad');
    } else if (!this.inscripcion.colegio) {
      validado = false;
      this._GlobalService.ToastInfo('Ingresé su colegio');
    } else if (!this.inscripcion.curso) {
      validado = false;
      this._GlobalService.ToastInfo('Ingresé su colegio');
    } else if (this.inscripcion.cod_carrera == 0) {
      validado = false;
      this._GlobalService.ToastInfo('Ingresé su carrera de interés');
    } else if (!this.inscripcion.celular) {
      validado = false;
      this._GlobalService.ToastInfo('Ingresé su celular');
    } else if (!this.inscripcion.correo) {
      validado = false;
      this._GlobalService.ToastInfo('Ingresé su correo');
    }





    return validado;
  }

  public fechaFormat(date) {
    let day: string = date.getDate().toString();
    day = +day < 10 ? '0' + day : day;
    let month: string = (date.getMonth() + 1).toString();
    month = +month < 10 ? '0' + month : month;
    let year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  public RegistrarTallerInscripcion() {
    this.inscripcion.fecha_nacimiento = this.fechaFormat(this.fecha_nacimiento);
    if (this.Validaciones() == true) {

      console.log("Taller POST", JSON.stringify(this.inscripcion));
      this.PostTallerIncripcion(this.inscripcion, 1);
      this.PostTallerIncripcion(this.inscripcion, 2);
      this.PostTallerIncripcion(this.inscripcion, 3);
      this.PostTallerIncripcion(this.inscripcion, 4);
      this.inscripcion = {
        instagram: "",
        colegio: "",
        celular: "",
        ciudad: "",
        cod_carrera: 0,
        cod_ins: 0,
        cod_taller: 0,
        correo: "",
        curso: "",
        fecha_nacimiento: "",
        nombre: "",
        rut: ""
      };
      this.fecha_nacimiento = new Date();
    }
  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  //Rutas
  public Redireccionar(tipo, cod) {
    switch (tipo) {
      case 1:
        this._Router.navigate(['/departamento-detalle/' + cod]);
        break;
      case 2:
        this._Router.navigate(['/carrera-detalle/' + cod]);
        break;
      // facultad-historia
      case 3:
        sessionStorage.setItem("reload-facultad-historia", JSON.stringify(true));
        this._Router.navigate(['/facultad-historia']);
        break;
      // facultad-mision-vision        
      case 4:
        sessionStorage.setItem("reload-facultad-mision-vision", JSON.stringify(true));
        this._Router.navigate(['/facultad-mision-vision']);
        break;
      // facultad-organigrama        
      case 5:
        sessionStorage.setItem("reload-facultad-organigrama", JSON.stringify(true));
        this._Router.navigate(['/facultad-organigrama']);
        break;
      // vinculacion        
      case 6:
        sessionStorage.setItem("reload-vinculacion", JSON.stringify(true));
        this._Router.navigate(['/vinculacion']);
        break;
      // departamento        
      case 7:
        sessionStorage.setItem("reload-departamento", JSON.stringify(true));
        this._Router.navigate(['/departamento']);
        break;
      // pregrado-listado        
      case 8:
        sessionStorage.setItem("reload-pregrado-listado", JSON.stringify(true));
        this._Router.navigate(['/pregrado-listado']);
        break;
      // postgrado-listado        
      case 9:
        sessionStorage.setItem("reload-postgrado-listado", JSON.stringify(true));
        this._Router.navigate(['/postgrado-listado']);
        break;
      // investigacion        
      case 10:
        sessionStorage.setItem("reload-investigacion", JSON.stringify(true));
        this._Router.navigate(['/investigacion-inicio']);
        break;
      // recursos        
      case 11:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos']);
        break;
      // viculacion medio        
      case 12:
        sessionStorage.setItem("reload-vinculacion-medio", JSON.stringify(true));
        this._Router.navigate(['/vinculacion-medio']);
        break;
      // recursos educacion        
      case 13:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-educacion']);
        break;
      // recursos coronavirus       
      case 14:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-corona']);
        break;
      // seminarios       
      case 15:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/seminarios']);
        break;
    }
  }


  //hexagonos 
  public RedireccionarHexa(tipo) {
    switch (tipo) {
      //Academicos Investigadores
      case 1:
        // sessionStorage.setItem("reload-facultad-historia", JSON.stringify(true));
        this._Router.navigate(['/hexagono-academico']);
        break;
      //Bachillerato
      case 2:
        // sessionStorage.setItem("reload-facultad-historia", JSON.stringify(true));
        this._Router.navigate(['/hexagono-bachillerato']);
        break;
      //Doctorado
      case 3:
        // sessionStorage.setItem("reload-facultad-historia", JSON.stringify(true));
        this._Router.navigate(['/hexagono-doctorado']);
        break;
      //Magister
      case 4:
        // sessionStorage.setItem("reload-facultad-historia", JSON.stringify(true));
        this._Router.navigate(['/hexagono-magister']);
        break;
      //Pregrado
      case 5:
        // sessionStorage.setItem("reload-facultad-historia", JSON.stringify(true));
        this._Router.navigate(['/hexagono-pregrado']);
        break;
    }
  }

  public GETCarrera() {
    this.Loading = !this.Loading;
    this._CarreraService.GETCarrera(1).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);

          console.log('carrera', datos);
          this.carreras = datos;
        } else {
          console.log('result', result)
          // this._GlobalService.ToastError();
        }
        this.Loading = !this.Loading;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
          // this._GlobalService.ToastError();
        }
        this.Loading = !this.Loading;
      }
    );
  }


  public GETSlider() {
    this._TallerService.GETTaller().subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let listado_actual = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            let pnew =  '<p class="mbr-section-title pb-3 mbr-fonts-style display-3 descripcion-taller">';
            element["descripcion"]  =  element["descripcion"].replace('<p>', pnew);
            console.log( element["descripcion"] )
            if (i == 0) {
              this.InicioSlider = element["img"];
            } else {
              listado.push(element);
            }
            listado_actual.push(element);
            i++;
          });
       
          this.ListadoSlider = listado;
          this.ListadoTallerActual = listado_actual;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public RedirectBanner(url) {
    window.open(url);
  }



  public PostSuscripcion(Datos) {
    this.Loading = !this.Loading;
    this._UsuarioService.PostSuscripcion(Datos).subscribe(
      result => {
        this.Loading = !this.Loading;
        if (result.status == 200) {
          let info = result.body;
          console.log(info)
          if (info == true) {
            // this._GlobalService.ToastOk();
            // this._Router.navigate(['/academico-listado']);
          }
        } else {
          console.log('result', result)
          // this._GlobalService.ToastError();
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
          // this._GlobalService.ToastError();
        }
        this.Loading = !this.Loading;
      }
    );
  }

  public RegistrarSuscripcion() {
    if (this.Validaciones() == true) {
      console.log(JSON.stringify(this.Suscripcion));
      this.PostSuscripcion(this.Suscripcion);
    }
  }

  public GETBanner() {
    this._SliderService.GETBannerId(2).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

}
