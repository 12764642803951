import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { TranslateService } from '@ngx-translate/core';
import { CarreraService } from '../../services/carrera.service';
import { DepartamentoService } from '../../services/departamento.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SliderService } from '../../services/slider.service';

@Component({
  selector: 'app-noticia-listado',
  templateUrl: './noticia-listado.component.html',
  styleUrls: ['./noticia-listado.component.css']
})
export class NoticiaListadoComponent implements OnInit {

  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public activeLang = 'es';

  public textoBusqueda = "";
  public NoticiaInicial: any;
  public Noticias = {
    noticia1: {},
    noticia2: {},
    noticia3: {},
    noticia4: {},
    noticia5: {},
    noticia6: {},
  }
  public ListadoNoticia: any;
  public Largo = 0;
  public Loading: boolean;

  constructor(private _Router: Router, private _GlobalService: GlobalService,private _SliderService:SliderService, private _DepartamentoService: DepartamentoService,
    private _CarreraService: CarreraService, private translate: TranslateService) {
    this.reload();

    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);

    this.NoticiaInicial = {};

   // this.Loading = !this.Loading;
    // let timer = setInterval(() => {
    //   this.Loading = !this.Loading;
    //   clearInterval(timer);
    // }, 1500);
    let info = JSON.parse(sessionStorage.getItem("noticia_listado"));
    let listado = [];
    for (let i = 0; i < info.length; i++) {
      if (i == 0) {
        this.NoticiaInicial = info[0];
      } else {
        listado.push(info[i]);
      }
    }
    this.OrdenarNoticias(listado);
    this.ListadoNoticia = listado;
    console.log(this.Noticias);
    console.log("Noticia inicial", this.NoticiaInicial);
    this.ListadoBanner = [];
    this.GETBanner();

  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-noticia-listado"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-noticia-listado", JSON.stringify(false));
      window.location.reload();
    }
  }

 public RedirectBanner(url){
    window.open(url);
  }

  public GETBanner() {
    this._SliderService.GETBannerId(2).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  ngOnInit() {

  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  //Rutas
  public Redireccionar(tipo, cod) {
    switch (tipo) {
      case 1:
        this._Router.navigate(['/departamento-detalle/'+cod]);
        break;
      case 2:
        this._Router.navigate(['/carrera-detalle/'+cod]);
        break;
      // facultad-historia
      case 3:
        sessionStorage.setItem("reload-facultad-historia", JSON.stringify(true));
        this._Router.navigate(['/facultad-historia']);
        break;
      // facultad-mision-vision        
      case 4:
        sessionStorage.setItem("reload-facultad-mision-vision", JSON.stringify(true));
        this._Router.navigate(['/facultad-mision-vision']);
        break;
      // facultad-organigrama        
      case 5:
        sessionStorage.setItem("reload-facultad-organigrama", JSON.stringify(true));
        this._Router.navigate(['/facultad-organigrama']);
        break;
      // vinculacion        
      case 6:
        sessionStorage.setItem("reload-vinculacion", JSON.stringify(true));
        this._Router.navigate(['/vinculacion']);
        break;
      // departamento        
      case 7:
        sessionStorage.setItem("reload-departamento", JSON.stringify(true));
        this._Router.navigate(['/departamento']);
        break;
      // pregrado-listado        
      case 8:
        sessionStorage.setItem("reload-pregrado-listado", JSON.stringify(true));
        this._Router.navigate(['/pregrado-listado']);
        break;
     // postgrado-listado        
      case 9:
        sessionStorage.setItem("reload-postgrado-listado", JSON.stringify(true));
        this._Router.navigate(['/postgrado-listado']);
        break;
      // investigacion        
      case 10:
        sessionStorage.setItem("reload-investigacion", JSON.stringify(true));
        this._Router.navigate(['/investigacion-inicio']);
        break;
    // recursos        
      case 11:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos']);
        break;
      // viculacion medio        
    case 12:
        sessionStorage.setItem("reload-vinculacion-medio", JSON.stringify(true));
        this._Router.navigate(['/vinculacion-medio']);
        break;
      // recursos educacion        
      case 13:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-educacion']);
        break;
      // recursos coronavirus       
      case 14:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-corona']);
        break;
      // seminarios       
      case 15:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/seminarios']);
        break;
    }
  }

  public OrdenarNoticias(datos) {
    for (let i = 0; i < datos.length; i++) {
      switch (i + 1) {
        case 1:
          this.Noticias.noticia1 = datos[i];
          break;
        case 2:
          this.Noticias.noticia2 = datos[i];
          break;
        case 3:
          this.Noticias.noticia3 = datos[i];
          break;
        case 4:
          this.Noticias.noticia4 = datos[i];
          break;
        case 5:
          this.Noticias.noticia5 = datos[i];
          break;
        case 6:
          this.Noticias.noticia6 = datos[i];
          break;
      }
      if (i == 5) {
        break;
      }
    }
  }

  public DetalleNoticia(noticia) {
    let info = {
      Noticia: noticia
    };
    sessionStorage.setItem("reload-noticia_detalle", JSON.stringify(true));
    sessionStorage.setItem("noticia_detalle", JSON.stringify(info));
    console.log(info);
    this._Router.navigate(['/noticia-detalle/' + noticia.cod_noticia]);
  }

  public Actualizar() {
    this.Largo = this.Largo + 1;
    let total = this.ListadoNoticia.length;
    let limite = 6 + this.Largo;

    console.log(total);
    console.log(limite);
    console.log(total < limite);

    if (limite <= total) {
      this.Noticias.noticia1 = this.ListadoNoticia[0 + this.Largo];
      this.Noticias.noticia2 = this.ListadoNoticia[1 + this.Largo];
      this.Noticias.noticia3 = this.ListadoNoticia[2 + this.Largo];
      this.Noticias.noticia4 = this.ListadoNoticia[3 + this.Largo];
      this.Noticias.noticia5 = this.ListadoNoticia[4 + this.Largo];
      this.Noticias.noticia6 = this.ListadoNoticia[5 + this.Largo];
    }
  }

  public buscarNoticia() {
    console.log(this.textoBusqueda);
    sessionStorage.setItem("reload-noticia-busqueda", JSON.stringify(true));
    sessionStorage.setItem("noticia-busqueda", JSON.stringify(this.textoBusqueda));
    this._Router.navigate(['/noticia-busqueda']);
  }

  public Entrar(event) {
    let inputChar = event.charCode;
    if (inputChar == 13) {
      this.buscarNoticia();
    }
  }

}
