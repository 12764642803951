import { Component, OnInit } from '@angular/core';
import { ContenidoService } from '../services/contenido.service';
import { GlobalService } from '../services/global.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { SliderService } from '../services/slider.service';
import { EventoService } from '../services/evento.service';
import { NoticiaService } from '../services/noticia.service';
import { CarreraService } from '../services/carrera.service';
import { HexagonoService } from '../services/hexagono.service';
import { DepartamentoService } from '../services/departamento.service';
import { TranslateService } from '@ngx-translate/core';
import { UsuarioService } from '../services/usuario.service';
declare var VanillaTilt;

@Component({
  selector: 'app-seminarios',
  templateUrl: './seminarios.component.html',
  styleUrls: ['./seminarios.component.css']
})
export class SeminariosComponent implements OnInit {

 
  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";

  public activeLang = 'es';

  public ListadoNoticias: any;
  public ListadoSlider: any;
  public ListadoEventos: any;
  public ListadoHexagono: any;

  public InicioSlider = "";

  public Noticias = {
    noticia1: {},
    noticia2: {},
    noticia3: {},
    noticia4: {},
    noticia5: {},
    noticia6: {},
  };

  public Eventos = {
    evento1: {},
    evento2: {},
  }

  public EventosPasados = {
    evento1: {},
    evento2: {},
    evento3: {},
  }

  public Loading: boolean;
  public Hexag: boolean;

  public RutaHexag = "";
  public TextoHexag = "";

  public Suscripcion = {
    correo: ""
  };

  constructor(private _Router: Router, private _ContenidoService: ContenidoService, private _GlobalService: GlobalService,
    private _SliderService: SliderService, private _EventoService: EventoService, private _NoticiaService: NoticiaService,
    private _HexagonoService: HexagonoService, private _DepartamentoService: DepartamentoService,
    private _CarreraService: CarreraService, private translate: TranslateService, private _UsuarioService: UsuarioService) {
    this.reload();

    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);

    this.ListadoNoticias = [];
    this.ListadoEventos = [];
    this.ListadoSlider = [];
    this.ListadoBanner = [];
    this.ListadoHexagono = [];
    this.GETSlider();
    this.GETBanner();
    this.GETHexagono();
    this.GETEvento();
    this.GETNoticia();
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-inicio"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-inicio", JSON.stringify(false));
      window.location.reload();
    }
  }


  ngOnInit() {
    VanillaTilt.init(document.querySelector(".tilt-image"), { max: 25, speed: 400 });

    VanillaTilt.init(document.querySelectorAll(".tilt-image"));
  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  //Rutas
  public Redireccionar(tipo, cod) {
    switch (tipo) {
      case 1:
        this._Router.navigate(['/departamento-detalle/' + cod]);
        break;
      case 2:
        this._Router.navigate(['/carrera-detalle/' + cod]);
        break;
      // facultad-historia
      case 3:
        sessionStorage.setItem("reload-facultad-historia", JSON.stringify(true));
        this._Router.navigate(['/facultad-historia']);
        break;
      // facultad-mision-vision        
      case 4:
        sessionStorage.setItem("reload-facultad-mision-vision", JSON.stringify(true));
        this._Router.navigate(['/facultad-mision-vision']);
        break;
      // facultad-organigrama        
      case 5:
        sessionStorage.setItem("reload-facultad-organigrama", JSON.stringify(true));
        this._Router.navigate(['/facultad-organigrama']);
        break;
      // vinculacion        
      case 6:
        sessionStorage.setItem("reload-vinculacion", JSON.stringify(true));
        this._Router.navigate(['/vinculacion']);
        break;
      // departamento        
      case 7:
        sessionStorage.setItem("reload-departamento", JSON.stringify(true));
        this._Router.navigate(['/departamento']);
        break;
      // pregrado-listado        
      case 8:
        sessionStorage.setItem("reload-pregrado-listado", JSON.stringify(true));
        this._Router.navigate(['/pregrado-listado']);
        break;
      // postgrado-listado        
      case 9:
        sessionStorage.setItem("reload-postgrado-listado", JSON.stringify(true));
        this._Router.navigate(['/postgrado-listado']);
        break;
      // investigacion        
      case 10:
        sessionStorage.setItem("reload-investigacion", JSON.stringify(true));
        this._Router.navigate(['/investigacion-inicio']);
        break;
      // recursos        
      case 11:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos']);
        break;
      // viculacion medio        
      case 12:
        sessionStorage.setItem("reload-vinculacion-medio", JSON.stringify(true));
        this._Router.navigate(['/vinculacion-medio']);
        break;
      // recursos educacion        
      case 13:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-educacion']);
        break;
      // recursos coronavirus       
      case 14:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-corona']);
        break;
      // seminarios       
      case 15:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/seminarios']);
        break;
    }
  }


  //hexagonos 
  public RedireccionarHexa(tipo) {
    switch (tipo) {
      //Academicos Investigadores
      case 1:
        // sessionStorage.setItem("reload-facultad-historia", JSON.stringify(true));
        this._Router.navigate(['/hexagono-academico']);
        break;
      //Bachillerato
      case 2:
        // sessionStorage.setItem("reload-facultad-historia", JSON.stringify(true));
        this._Router.navigate(['/hexagono-bachillerato']);
        break;
      //Doctorado
      case 3:
        // sessionStorage.setItem("reload-facultad-historia", JSON.stringify(true));
        this._Router.navigate(['/hexagono-doctorado']);
        break;
      //Magister
      case 4:
        // sessionStorage.setItem("reload-facultad-historia", JSON.stringify(true));
        this._Router.navigate(['/hexagono-magister']);
        break;
      //Pregrado
      case 5:
        // sessionStorage.setItem("reload-facultad-historia", JSON.stringify(true));
        this._Router.navigate(['/hexagono-pregrado']);
        break;
    }
  }


  public MasNoticias() {
    sessionStorage.setItem("reload-noticias-mas", JSON.stringify(true));
    this._Router.navigate(['/noticias-mas']);
  }

  public GETSlider() {
    this._SliderService.GETSlider().subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioSlider = element["img"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoSlider = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public RedirectBanner(url) {
    window.open(url);
  }

  public Validaciones(): boolean {
    let validado = true;

    if (!this.Suscripcion.correo) {
      validado = false;
      // this._GlobalService.ToastInfo('Ingrese la url del banner');
    }
    return validado;
  }

  public PostSuscripcion(Datos) {
    this.Loading = !this.Loading;
    this._UsuarioService.PostSuscripcion(Datos).subscribe(
      result => {
        this.Loading = !this.Loading;
        if (result.status == 200) {
          let info = result.body;
          console.log(info)
          if (info == true) {
            // this._GlobalService.ToastOk();
            // this._Router.navigate(['/academico-listado']);
          }
        } else {
          console.log('result', result)
          // this._GlobalService.ToastError();
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
          // this._GlobalService.ToastError();
        }
        this.Loading = !this.Loading;
      }
    );
  }

  public RegistrarSuscripcion() {
    if (this.Validaciones() == true) {
      console.log(JSON.stringify(this.Suscripcion));
      this.PostSuscripcion(this.Suscripcion);
    }
  }

  public GETBanner() {
    this._SliderService.GETBannerId(2).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public GETHexagono() {
    this._HexagonoService.GETHexagono().subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
          });
          this.ListadoHexagono = datos;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public GETEvento() {
    this._EventoService.GETEvento().subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let fecha_actual = new Date();
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            element["hora_inicio"] = element["hora_inicio"].substring(0, 5);
            element["hora_termino"] = element["hora_termino"].substring(0, 5);


            var desc = element["descripcion"];
            if (element["descripcion"].length >= 80) {
              desc = element["descripcion"].substring(0, 80) + "...";
            }

            var desc_in = element["descripcion_ingles"];
            if (element["descripcion_ingles"].length >= 80) {
              desc_in = element["descripcion_ingles"].substring(0, 80) + "...";
            }
  
            element["desc_corto"] = desc;
            element["desc_corto_ingles"] = desc_in;

          });
          console.log("Listado evento", datos);
          let listado_mayor = datos.filter(function (element) {
            return new Date(element.fecha_inicio) >= fecha_actual;
          });
          console.log("Listado mayor", listado_mayor);
          let listado_menor = datos.filter(function (element) {
            return new Date(element.fecha_inicio) < fecha_actual;
          });
          console.log("Listado menor", listado_menor);
          this.OrdenarEventos(datos);
          this.OrdenarEventosPasados(listado_menor);
          this.ListadoEventos = datos;
          sessionStorage.setItem("evento_listado", JSON.stringify(datos));
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public GETNoticia() {
    this._NoticiaService.GETNoticia().subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];

            var desc = element["titulo"];
            if (element["titulo"].length >= 55) {
              desc = element["titulo"].substring(0, 55) + "...";
            }

            var desc_in = element["titulo_ingles"];
            if (element["titulo_ingles"].length >= 55) {
              desc_in = element["titulo_ingles"].substring(0, 55) + "...";
            }
  
            element["desc_corto"] = desc;
            element["desc_corto_ingles"] = desc_in;
          });
          this.OrdenarNoticias(datos);
          this.ListadoNoticias = datos;
          sessionStorage.setItem("noticia_listado", JSON.stringify(datos));
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public OrdenarNoticias(datos) {
    for (let i = 0; i < datos.length; i++) {
      switch (i + 1) {
        case 1:
          this.Noticias.noticia1 = datos[i];
          break;
        case 2:
          this.Noticias.noticia2 = datos[i];
          break;
        case 3:
          this.Noticias.noticia3 = datos[i];
          break;
        case 4:
          this.Noticias.noticia4 = datos[i];
          break;
        case 5:
          this.Noticias.noticia5 = datos[i];
          break;
        case 6:
          this.Noticias.noticia6 = datos[i];
          break;
      }
      if (i == 5) {
        break;
      }
    }
  }

  public OrdenarEventos(datos) {
    for (let i = 0; i < datos.length; i++) {
      switch (i + 1) {
        case 1:
          this.Eventos.evento1 = datos[i];
          break;
        case 2:
          this.Eventos.evento2 = datos[i];
          break;
      }
      if (i == 1) {
        break;
      }
    }
  }

  public OrdenarEventosPasados(datos) {
    for (let i = 0; i < datos.length; i++) {
      switch (i + 1) {
        case 1:
          this.EventosPasados.evento1 = datos[i];
          break;
        case 2:
          this.EventosPasados.evento2 = datos[i];
          break;
        case 3:
          this.EventosPasados.evento3 = datos[i];
          break;
      }
      if (i == 2) {
        break;
      }
    }
  }

  public DetalleNoticias(noticia) {
    let info = {
      Noticia: noticia
    };
    sessionStorage.setItem("reload-noticia_detalle", JSON.stringify(true));
    sessionStorage.setItem("noticia_detalle", JSON.stringify(info));
    console.log(info);
    this._Router.navigate(['/noticia-detalle/' + noticia.cod_noticia]);
  }

  public DetalleEvento(evento) {
    let info = {
      Evento: evento
    };
    sessionStorage.setItem("reload-evento_detalle", JSON.stringify(true));
    sessionStorage.setItem("evento_detalle", JSON.stringify(info));
    console.log(info);
    this._Router.navigate(['/evento-detalle/' + evento.cod_evento]);
  }

  public ModalHexagono(opcion) {
    let rutaHexag = "assets/imgs/hexagono/" + opcion + ".png";
    let texto = "";
    switch (opcion) {
      case "01":
        texto = "VER ACADÉMICOS";
        break;
      case "02":
        texto = "VER BACHILLERATO";
        break;
      case "03":
        texto = "VER PROGRAMAS DE DOCTORADO";
        break;
      case "04":
        texto = "VER PROGRAMAS DE MAGÍSTER";
        break;
      case "05":
        texto = "VER PREGRADOS";
        break;
      case "06":
        texto = "VER PUBLICACIONES";
        break;
      case "07":
        texto = "Departamento de Ciencias Básicas".toUpperCase();
        break;
      case "08":
        texto = "Departamento de Estadística".toUpperCase();
        break;
      case "09":
        texto = "Departamento de Física".toUpperCase();
        break;
      case "10":
        texto = "Departamento de Matemática".toUpperCase();
        break;
      case "11":
        texto = "Departamento de de Química".toUpperCase();
        break;

    }
    this.TextoHexag = texto;
    this.RutaHexag = rutaHexag;
    this.Hexag = !this.Hexag;
  }

  public CerrarHexagono() {
    this.Hexag = !this.Hexag;
  }


  public MostrarTituloHex(opcion) {
    let rutaHexag = "assets/imgs/hexagono/" + opcion + ".png";
    let texto = "";
    switch (opcion) {
      case "01":
        texto = "ACADÉMICOS";
        break;
      case "02":
        texto = "BACHILLERATO";
        break;
      case "03":
        texto = "PROGRAMAS DE DOCTORADO";
        break;
      case "04":
        texto = "PROGRAMAS DE MAGÍSTER";
        break;
      case "05":
        texto = "PREGRADOS";
        break;
      case "06":
        texto = "PUBLICACIONES";
        break;
      case "07":
        texto = "Departamento Ciencias Básicas".toUpperCase();
        break;
      case "08":
        texto = "Departamento Estadística".toUpperCase();
        break;
      case "09":
        texto = "Departamento Física".toUpperCase();
        break;
      case "10":
        texto = "Departamento Matemática".toUpperCase();
        break;
      case "11":
        texto = "Departamento de Química".toUpperCase();
        break;

    }
    this.TextoHexag = texto;
    this.RutaHexag = rutaHexag;
  }

  public RediHexagono(opcion) {
    let ruta = "";
    switch (opcion) {
      case "01":
        // ruta = "VER ACADEMICOS";
        ruta = '/'
        break;
      case "02":
        ruta = '/'
        // ruta = "VER BACHILLERATO";
        break;
      case "03":
        ruta = '/'
        // ruta = "VER PROGRAMAS DE DOCTORADO";
        break;
      case "04":
        ruta = '/'
        // ruta = "VER PROGRAMAS DE MAGISTER";
        break;
      case "05":
        ruta = '/'
        // ruta = "VER PREGRADOS";
        break;
      case "06":
        ruta = '/publicaciones-total'
        // ruta = "VER PUBLICACIONES";
        break;
      case "07":
        ruta = '/'
        // ruta = "Depto Cs. Básicas".toUpperCase();
        break;
      case "08":
        ruta = '/'
        // ruta = "Depto Estadística".toUpperCase();
        break;
      case "09":
        ruta = '/'
        // ruta = "Depto Física".toUpperCase();
        break;
      case "10":
        ruta = '/'
        // ruta = "Depto Matemática".toUpperCase();
        break;
      case "11":
        ruta = '/'
        // ruta = "Depto de Química".toUpperCase();
        break;

    }
    this._Router.navigate(['/publicaciones-total']);
  }
}
