import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';

@Injectable({
    providedIn: 'root'
})
export class DepartamentoService {
    private url: string;
    constructor(private _HttpClient: HttpClient,
        private _GlobalService: GlobalService) {
        this.url = _GlobalService.UrlApi();
    }

    public GETDepartamento() {
        var urlFull = this.url + 'departamento';
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GETDepartamentoID(id) {
        var urlFull = this.url + 'departamento/'+id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GETDepartamentoPublicacionID(id) {
        var urlFull = this.url + 'departamento/publicacion/'+id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GetFuncionarioCurriculum(Id) {
        var urlFull = this.url + 'funcionariocurriculum/'+Id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GetPublicaciones() {
        var urlFull = this.url + 'publicaciones';
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GetAcademicoCurriculum(Id) {
        var urlFull = this.url + 'academicocurriculum/'+Id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GetAcademicoCurriculumTipo(id,tipo) {
        var urlFull = this.url + 'academicocurriculum/'+id+'/'+tipo;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GETDepartamentoFuncionario(Tipo) {
        var urlFull = this.url + 'departamentofuncionario/'+Tipo;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GETDepartamentoAcademico(Id) {
        var urlFull = this.url + 'departamentoacademico/'+Id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GETAcademico() {
        var urlFull = this.url + 'academico';
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }


    public GETAcademicoId(Id) {
        var urlFull = this.url + 'academico/'+Id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GETDepartamentoInvestigacionAreas() {
        var urlFull = this.url + 'departamentoareas';
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GetDepartamentoGaleria(Id) {
        var urlFull = this.url + 'departamentogaleria/' + Id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GetDepartamentoContacto(Id) {
        var urlFull = this.url + 'departamentocontacto/' + Id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }


    public GetEstudianteInvestigacion(id) {
        var urlFull = this.url + 'estudianteinvestigacion/'+id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GetEstudianteInvestigacionId(id) {
        var urlFull = this.url + 'estudianteinvestigacionid/'+id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }
}
