import { Component, OnInit } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { CarreraService } from "../../services/carrera.service";
import { GlobalService } from "../../services/global.service";
import { TranslateService } from "@ngx-translate/core";
import { DepartamentoService } from "../../services/departamento.service";
import { SliderService } from "../../services/slider.service";
declare var VanillaTilt;

@Component({
  selector: "app-academico-detalle",
  templateUrl: "./academico-detalle.component.html",
  styleUrls: ["./academico-detalle.component.css"],
})
export class AcademicoDetalleComponent implements OnInit {
  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";

  public activeLang = "es";

  public academico = {
    cod_academico: 0,
    cod_departamento: 0,
    nombre: "",
    titulo: "",
    correo: "",
    fono: "",
    campus: "",
    area_investigacion: "",
    info_general: "",
    img: "",
    base64: "",
    cambio: 0,
  };

  public info_general = {
    cod_curriculum: 0,
    cod_academico: 0,
    descripcion: "",
    fecha: null,
    tipo: "General",
    tipo_texto: 2,
    orden: 0,
  };

  public info_general_ingles = {
    cod_curriculum: 0,
    cod_academico: 0,
    descripcion: "",
    fecha: null,
    tipo: "General_Ingles",
    tipo_texto: 2,
    orden: 0,
  };

  public info_biografia = {
    cod_curriculum: 0,
    cod_academico: 0,
    descripcion: "",
    fecha: null,
    tipo: "Biografia",
    tipo_texto: 2,
    orden: 0,
  };

  public info_biografia_ingles = {
    cod_curriculum: 0,
    cod_academico: 0,
    descripcion: "",
    fecha: null,
    tipo: "Biografia_Ingles",
    tipo_texto: 2,
    orden: 0,
  };

  public general: boolean;
  public publicacion: boolean;
  public investigacion: boolean;
  public ensenaza: boolean;
  public biografia: boolean;
  public supervision: boolean;

  public listadoGeneral: any;
  public listadoGeneralIngles: any;
  public listadoPublicacion: any;
  public listadoInvestigacion: any;
  public listadoEnsenaza: any;
  public listadoBiografia: any;
  public listadoBiografiaIngles: any;

  public ListadoEstudiantes: any;

  public Loading: boolean;
  public TextoHexag = "";

  constructor(
    private _Router: Router,
    private _ActivatedRoute: ActivatedRoute,
    private _SliderService: SliderService,
    private _GlobalService: GlobalService,
    private _DepartamentoService: DepartamentoService,
    private _CarreraService: CarreraService,
    private translate: TranslateService
  ) {
    this.reload();
    this.Loading = !this.Loading;
    // let info = JSON.parse(sessionStorage.getItem("academico_detalle"));
    // this.academico = info;
    //info
    this.general = true;
    this.publicacion = false;
    this.investigacion = false;
    this.ensenaza = false;
    this.biografia = false;
    this.supervision = false;
    this.listadoGeneral = [];
    this.listadoPublicacion = [];
    this.listadoInvestigacion = [];
    this.listadoEnsenaza = [];
    this.listadoBiografia = [];
    //
    let timer = setInterval(() => {
      this.Loading = !this.Loading;
      clearInterval(timer);
    }, 1500);
    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);
    // this.GetAcademicoCurriculum();
    // this.GetEstudianteInvestigacion()
    this.ListadoBanner = [];
    this.GETBanner();
  }

  ngOnInit() {
    this._ActivatedRoute.paramMap.subscribe((params) => {
      if (params.has("id")) {
        let cod = params.get("id");
        // this.Loading = !this.Loading;
        this._DepartamentoService.GETAcademicoId(cod).subscribe(
          (result) => {
            if (result.status == 200) {
              let datos: any = result.body;
              datos["img"] = this._GlobalService.UrlArchivos() + datos["img"];
              this.academico = datos;
              // this.GetAcademicoCurriculum();
              this.GetAcademicoCurriculumTipo("General");
              this.GetAcademicoCurriculumTipo("General_Ingles");
              this.GetAcademicoCurriculumTipo("Publicacion");
              this.GetAcademicoCurriculumTipo("Investigacion");
              this.GetAcademicoCurriculumTipo("Enseñanza");
              this.GetAcademicoCurriculumTipo("Biografia");
              this.GetAcademicoCurriculumTipo("Biografia_Ingles");
              this.GetEstudianteInvestigacion();
              // sessionStorage.setItem("academico_listado", JSON.stringify(datos));
            } else {
              console.log("result", result);
            }
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log("An error occurred:", err.error.message);
            }
          }
        );
      } else {
        this._Router.navigate(["/"]);
      }
    });
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-academico-detalle"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-academico-detalle", JSON.stringify(false));
      window.location.reload();
    }
  }

  public RedirectBanner(url) {
    window.open(url);
  }

  public GETBanner() {
    this._SliderService.GETBannerId(2).subscribe(
      (result) => {
        if (result.status == 200) {
          let datos: any = result.body;
          let listado = [];
          let i = 0;
          datos.forEach((element) => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log("result", result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log("An error occurred:", err.error.message);
        }
      }
    );
  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  //Rutas
  public Redireccionar(tipo, cod) {
    switch (tipo) {
      case 1:
        this._Router.navigate(["/departamento-detalle/" + cod]);
        break;
      case 2:
        this._Router.navigate(["/carrera-detalle/" + cod]);
        break;
      // facultad-historia
      case 3:
        sessionStorage.setItem(
          "reload-facultad-historia",
          JSON.stringify(true)
        );
        this._Router.navigate(["/facultad-historia"]);
        break;
      // facultad-mision-vision
      case 4:
        sessionStorage.setItem(
          "reload-facultad-mision-vision",
          JSON.stringify(true)
        );
        this._Router.navigate(["/facultad-mision-vision"]);
        break;
      // facultad-organigrama
      case 5:
        sessionStorage.setItem(
          "reload-facultad-organigrama",
          JSON.stringify(true)
        );
        this._Router.navigate(["/facultad-organigrama"]);
        break;
      // vinculacion
      case 6:
        sessionStorage.setItem("reload-vinculacion", JSON.stringify(true));
        this._Router.navigate(["/vinculacion"]);
        break;
      // departamento
      case 7:
        sessionStorage.setItem("reload-departamento", JSON.stringify(true));
        this._Router.navigate(["/departamento"]);
        break;
      // pregrado-listado
      case 8:
        sessionStorage.setItem("reload-pregrado-listado", JSON.stringify(true));
        this._Router.navigate(["/pregrado-listado"]);
        break;
      // postgrado-listado
      case 9:
        sessionStorage.setItem(
          "reload-postgrado-listado",
          JSON.stringify(true)
        );
        this._Router.navigate(["/postgrado-listado"]);
        break;
      // investigacion
      case 10:
        sessionStorage.setItem("reload-investigacion", JSON.stringify(true));
        this._Router.navigate(["/investigacion-inicio"]);
        break;
      // recursos
      case 11:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(["/facultad-recursos"]);
        break;
      // viculacion medio
      case 12:
        sessionStorage.setItem(
          "reload-vinculacion-medio",
          JSON.stringify(true)
        );
        this._Router.navigate(["/vinculacion-medio"]);
        break;
      // recursos educacion
      case 13:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(["/facultad-recursos-educacion"]);
        break;
      // recursos coronavirus
      case 14:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(["/facultad-recursos-corona"]);
        break;
      // seminarios
      case 15:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(["/seminarios"]);
        break;
    }
  }

  public InfoCurriculum(tipo) {
    switch (tipo) {
      case 1:
        this.general = true;
        this.publicacion = false;
        this.investigacion = false;
        this.ensenaza = false;
        this.biografia = false;
        this.supervision = false;
        break;
      case 2:
        this.general = false;
        this.publicacion = true;
        this.investigacion = false;
        this.ensenaza = false;
        this.biografia = false;
        this.supervision = false;
        break;
      case 3:
        this.general = false;
        this.publicacion = false;
        this.investigacion = true;
        this.ensenaza = false;
        this.biografia = false;
        this.supervision = false;
        break;
      case 4:
        this.general = false;
        this.publicacion = false;
        this.investigacion = false;
        this.ensenaza = true;
        this.biografia = false;
        this.supervision = false;
        break;
      case 5:
        this.general = false;
        this.publicacion = false;
        this.investigacion = false;
        this.ensenaza = false;
        this.biografia = true;
        this.supervision = false;
        break;
      case 6:
        this.general = false;
        this.publicacion = false;
        this.investigacion = false;
        this.ensenaza = false;
        this.biografia = false;
        this.supervision = true;
        break;
    }
  }

  public formatoPublicacion(contenido: string) {
    // Reemplazar **Texto** por <strong>Texto</strong>
    contenido = contenido.replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>");

    // Reemplazar *Texto* por <i>Texto</i>
    contenido = contenido.replace(/\*(.*?)\*/g, "<i>$1</i>");

    return contenido;
  }

  public GetAcademicoCurriculum() {
    this.Loading = !this.Loading;
    this._DepartamentoService
      .GetAcademicoCurriculum(this.academico.cod_academico)
      .subscribe(
        (result) => {
          if (result.status == 200) {
            let datos: any = result.body;
            console.log("Curriculum", datos);

            this.listadoGeneral = datos.filter(function (element) {
              return element.tipo == "General";
            });

            this.listadoPublicacion = datos.filter(function (element) {
              return element.tipo == "Publicacion";
            });

            this.listadoPublicacion.forEach((element) => {
              element.descripcion = this.formatoPublicacion(
                element.descripcion
              );
            });

            this.listadoInvestigacion = datos.filter(function (element) {
              return element.tipo == "Investigacion";
            });

            this.listadoEnsenaza = datos.filter(function (element) {
              return element.tipo == "Enseñanza";
            });

            this.listadoBiografia = datos.filter(function (element) {
              return element.tipo == "Biografia";
            });
          } else {
            console.log("result", result);
          }
          this.Loading = !this.Loading;
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("An error occurred:", err.error.message);
          }
          this.Loading = !this.Loading;
        }
      );
  }

  public GetAcademicoCurriculumTipo(tipo) {
    this.Loading = !this.Loading;
    this._DepartamentoService
      .GetAcademicoCurriculumTipo(this.academico.cod_academico, tipo)
      .subscribe(
        (result) => {
          if (result.status == 200) {
            let datos: any = result.body;
            console.log("academico", datos);
            switch (tipo) {
              case "General":
                this.listadoGeneral = datos;
                datos.forEach((element) => {
                  this.info_general = element;
                });
                for (let index = 0; index < 100; index++) {
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace(
                      'class="ql-font-BAHNSCHRIFT"',
                      'class="display-5"'
                    );
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace(
                      '<p class="ql-align-justify">',
                      '<p class="display-5 ql-align-justify">'
                    );

                  this.info_general.descripcion =
                    this.info_general.descripcion.replace(
                      "<span s",
                      '<span class="display-5"  align="justify" s'
                    );
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace(
                      '<span style="background-color: transparent;">',
                      '<span class="display-5" align="justify" style="background-color: transparent;">'
                    );

                  this.info_general.descripcion =
                    this.info_general.descripcion.replace(
                      '<span style="background-color: transparent; color: rgb(0, 0, 0);">',
                      '<span class="display-5" align="justify">'
                    );
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace(
                      '<strong style="background-color: transparent; color: rgb(0, 0, 0);">',
                      '<strong class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" style="background-color: transparent; color: rgb(0, 0, 0);">'
                    );
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace(
                      "<h1>",
                      '<h1 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">'
                    );
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace(
                      "<li>",
                      '<li class="display-5" align="justify">'
                    );
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace(
                      "<p>",
                      '<p class="display-5" align="justify">'
                    );
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace("<h1", "<h3");
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace("h1>", "h3>");
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace("<strong", "<h3");
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace("strong>", "h3>");
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace(
                      "<h3>",
                      '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">'
                    );
                  this.info_general.descripcion =
                    this.info_general.descripcion.replace(
                      "<h3 s",
                      '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" s'
                    );
                }
                break;
              case "General_Ingles":
                this.listadoGeneralIngles = datos;
                datos.forEach((element) => {
                  this.info_general_ingles = element;
                });
                for (let index = 0; index < 100; index++) {
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      'class="ql-font-BAHNSCHRIFT"',
                      'class="display-5"'
                    );
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      '<p class="ql-align-justify">',
                      '<p class="display-5 ql-align-justify">'
                    );

                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      "<span s",
                      '<span class="display-5"  align="justify" s'
                    );
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      '<span style="background-color: transparent;">',
                      '<span class="display-5" align="justify" style="background-color: transparent;">'
                    );

                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      '<span style="background-color: transparent; color: rgb(0, 0, 0);">',
                      '<span class="display-5" align="justify">'
                    );
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      '<strong style="background-color: transparent; color: rgb(0, 0, 0);">',
                      '<strong class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" style="background-color: transparent; color: rgb(0, 0, 0);">'
                    );
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      "<h1>",
                      '<h1 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">'
                    );
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      "<li>",
                      '<li class="display-5" align="justify">'
                    );
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      "<p>",
                      '<p class="display-5" align="justify">'
                    );
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace("<h1", "<h3");
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace("h1>", "h3>");
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      "<strong",
                      "<h3"
                    );
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      "strong>",
                      "h3>"
                    );
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      "<h3>",
                      '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">'
                    );
                  this.info_general_ingles.descripcion =
                    this.info_general_ingles.descripcion.replace(
                      "<h3 s",
                      '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" s'
                    );
                }
                break;
              case "Publicacion":
                datos.forEach((element) => {
                  element.descripcion = this.formatoPublicacion(
                    element.descripcion
                  );
                });
                this.listadoPublicacion = datos;
                break;
              case "Investigacion":
                this.listadoInvestigacion = datos;
                break;
              case "Enseñanza":
                this.listadoEnsenaza = datos;
                break;
              case "Biografia":
                this.listadoBiografia = datos;
                datos.forEach((element) => {
                  this.info_biografia = element;
                });

                for (let index = 0; index < 100; index++) {
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace(
                      'class="ql-font-BAHNSCHRIFT"',
                      'class="display-5"'
                    );
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace(
                      '<p class="ql-align-justify">',
                      '<p class="display-5 ql-align-justify">'
                    );

                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace(
                      "<span s",
                      '<span class="display-5"  align="justify" s'
                    );
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace(
                      '<span style="background-color: transparent;">',
                      '<span class="display-5" align="justify" style="background-color: transparent;">'
                    );

                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace(
                      '<span style="background-color: transparent; color: rgb(0, 0, 0);">',
                      '<span class="display-5" align="justify">'
                    );
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace(
                      '<strong style="background-color: transparent; color: rgb(0, 0, 0);">',
                      '<strong class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" style="background-color: transparent; color: rgb(0, 0, 0);">'
                    );
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace(
                      "<h1>",
                      '<h1 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">'
                    );
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace(
                      "<li>",
                      '<li class="display-5" align="justify">'
                    );
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace(
                      "<p>",
                      '<p class="display-5" align="justify">'
                    );
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace("<h1", "<h3");
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace("h1>", "h3>");
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace("<strong", "<h3");
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace("strong>", "h3>");
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace(
                      "<h3>",
                      '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">'
                    );
                  this.info_biografia.descripcion =
                    this.info_biografia.descripcion.replace(
                      "<h3 s",
                      '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" s'
                    );
                }
                break;
              case "Biografia_Ingles":
                this.listadoBiografiaIngles = datos;
                datos.forEach((element) => {
                  this.info_biografia_ingles = element;
                });

                for (let index = 0; index < 100; index++) {
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      'class="ql-font-BAHNSCHRIFT"',
                      'class="display-5"'
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      '<p class="ql-align-justify">',
                      '<p class="display-5 ql-align-justify">'
                    );

                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      "<span s",
                      '<span class="display-5"  align="justify" s'
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      '<span style="background-color: transparent;">',
                      '<span class="display-5" align="justify" style="background-color: transparent;">'
                    );

                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      '<span style="background-color: transparent; color: rgb(0, 0, 0);">',
                      '<span class="display-5" align="justify">'
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      '<strong style="background-color: transparent; color: rgb(0, 0, 0);">',
                      '<strong class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" style="background-color: transparent; color: rgb(0, 0, 0);">'
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      "<h1>",
                      '<h1 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">'
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      "<li>",
                      '<li class="display-5" align="justify">'
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      "<p>",
                      '<p class="display-5" align="justify">'
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      "<h1",
                      "<h3"
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      "h1>",
                      "h3>"
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      "<strong",
                      "<h3"
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      "strong>",
                      "h3>"
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      "<h3>",
                      '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">'
                    );
                  this.info_biografia_ingles.descripcion =
                    this.info_biografia_ingles.descripcion.replace(
                      "<h3 s",
                      '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" s'
                    );
                }
                break;
            }

            // console.log("Listado General", JSON.stringify(datos));
          } else {
            console.log("result", result);
            // this._GlobalService.ToastError();
          }
          this.Loading = !this.Loading;
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("An error occurred:", err.error.message);
            // this._GlobalService.ToastError();
          }
          this.Loading = !this.Loading;
        }
      );
  }

  public GetEstudianteInvestigacion() {
    this._DepartamentoService
      .GetEstudianteInvestigacion(this.academico.cod_academico)
      .subscribe(
        (result) => {
          if (result.status == 200) {
            let datos: any = result.body;
            datos.forEach((element) => {
              element["img"] =
                this._GlobalService.UrlArchivos() + element["img"];
            });
            this.ListadoEstudiantes = datos;
            console.log(datos);
            sessionStorage.setItem("estudiante_listado", JSON.stringify(datos));
          } else {
            console.log("result", result);
          }
        },
        (err: HttpErrorResponse) => {
          if (err.error instanceof Error) {
            console.log("An error occurred:", err.error.message);
          }
        }
      );
  }

  public MostrarTituloHex(texto) {
    this.TextoHexag = texto;
  }

  public DetalleEstudiante(estudiante) {
    let info = {
      estudiante: estudiante,
    };
    sessionStorage.setItem("reload-academico-alumno", JSON.stringify(true));
    sessionStorage.setItem("academico-alumno", JSON.stringify(info));
    console.log(info);
    this._Router.navigate(["/academico-alumno/" + estudiante.cod_estudiante]);
  }
}
