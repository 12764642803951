import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';

@Injectable({
    providedIn: 'root'
})
export class NoticiaService {
    private url: string;
    constructor(private _HttpClient: HttpClient,
        private _GlobalService: GlobalService) {
        this.url = _GlobalService.UrlApi();
    }

    public GETNoticiaFiltro(filtro) {
        var urlFull = this.url + 'noticiafiltro/'+filtro;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GETNoticia() {
        var urlFull = this.url + 'noticia';
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GETNoticiaVistaId(id) {
        var urlFull = this.url + 'noticiavista/'+id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GETNoticiaId(id) {
        var urlFull = this.url + 'noticia/'+id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GETNoticiaDetalle(id) {
        var urlFull = this.url + 'noticiadetalle/' + id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GetCarreraNoticias(id) {
        var urlFull = this.url + 'carreranoticia/' + id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }
}
