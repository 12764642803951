import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { DepartamentoService } from '../../services/departamento.service';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { CarreraService } from '../../services/carrera.service';
import { SliderService } from '../../services/slider.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-funcionario-listado',
  templateUrl: './funcionario-listado.component.html',
  styleUrls: ['./funcionario-listado.component.css']
})
export class FuncionarioListadoComponent implements OnInit {

  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public activeLang = 'es';

  public departamento = {
    cod_departamento: 0,
    nombre: "",
    img: "",
    descripcion: "",
    web_oficial: "",
    tipo: 0,
    base64: "",
    cambio: 0
  };

  public textoBusqueda = "";
  public ListadoFuncionarios: any;
  public ListadoFuncionariosInicial: any;
  public Loading: boolean;

  public page_size = 6;
  public page_number = 1;
  public pageSizeOptions = [3, 6, 9, 18, 36];
  public largo_listado = 0;

  constructor(private _Router: Router, private _ActivatedRoute: ActivatedRoute,private _SliderService:SliderService, private _DepartamentoService: DepartamentoService, private _GlobalService: GlobalService,
    private _CarreraService: CarreraService, private translate: TranslateService) {
    this.reload();

    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);

    this.Loading = !this.Loading;
    let info = JSON.parse(sessionStorage.getItem("departamento_detalle"));
    this.departamento = info
    let timer = setInterval(() => {
      this.Loading = !this.Loading;
      clearInterval(timer);
    }, 1500);

    this.departamento.nombre = this.departamento.nombre.toUpperCase();

    this.GETDepartamentoFuncionario(this.departamento.cod_departamento);
    this.ListadoBanner = [];
    this.GETBanner();
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-funcionario-listado"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-funcionario-listado", JSON.stringify(false));
      window.location.reload();
    }
  }

  public handlePage(e:PageEvent){
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }

 public RedirectBanner(url){
    window.open(url);
  }

  public GETBanner() {
    this._SliderService.GETBannerId(2).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  ngOnInit() {
    this._ActivatedRoute.paramMap.subscribe(params => {
      if (params.has("id")) {
        let cod = params.get("id");
        this._DepartamentoService.GETDepartamentoFuncionario(cod).subscribe(
          result => {
            if (result.status == 200) {
              let datos: any = (result.body);
              datos.forEach(element => {
                element["img"] = this._GlobalService.UrlArchivos() + element["img"];
              });
              this.ListadoFuncionariosInicial = datos;
              this.ListadoFuncionarios = datos;
              this.largo_listado = datos.length;
              console.log(datos);
              sessionStorage.setItem("administrativo_listado", JSON.stringify(datos));
            } else {
              console.log('result', result);
            }
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log('An error occurred:', err.error.message);
            }
          }
        );
      } else {
        this._Router.navigate(['/']);
      }
    });
  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  //Rutas
  public Redireccionar(tipo, cod) {
    switch (tipo) {
      case 1:
        this._Router.navigate(['/departamento-detalle/'+cod]);
        break;
      case 2:
        this._Router.navigate(['/carrera-detalle/'+cod]);
        break;
      // facultad-historia
      case 3:
        sessionStorage.setItem("reload-facultad-historia", JSON.stringify(true));
        this._Router.navigate(['/facultad-historia']);
        break;
      // facultad-mision-vision        
      case 4:
        sessionStorage.setItem("reload-facultad-mision-vision", JSON.stringify(true));
        this._Router.navigate(['/facultad-mision-vision']);
        break;
      // facultad-organigrama        
      case 5:
        sessionStorage.setItem("reload-facultad-organigrama", JSON.stringify(true));
        this._Router.navigate(['/facultad-organigrama']);
        break;
      // vinculacion        
      case 6:
        sessionStorage.setItem("reload-vinculacion", JSON.stringify(true));
        this._Router.navigate(['/vinculacion']);
        break;
      // departamento        
      case 7:
        sessionStorage.setItem("reload-departamento", JSON.stringify(true));
        this._Router.navigate(['/departamento']);
        break;
      // pregrado-listado        
      case 8:
        sessionStorage.setItem("reload-pregrado-listado", JSON.stringify(true));
        this._Router.navigate(['/pregrado-listado']);
        break;
     // postgrado-listado        
      case 9:
        sessionStorage.setItem("reload-postgrado-listado", JSON.stringify(true));
        this._Router.navigate(['/postgrado-listado']);
        break;
      // investigacion        
      case 10:
        sessionStorage.setItem("reload-investigacion", JSON.stringify(true));
        this._Router.navigate(['/investigacion-inicio']);
        break;
    // recursos        
      case 11:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos']);
        break;
      // viculacion medio        
    case 12:
        sessionStorage.setItem("reload-vinculacion-medio", JSON.stringify(true));
        this._Router.navigate(['/vinculacion-medio']);
        break;
      // recursos educacion        
      case 13:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-educacion']);
        break;
      // recursos coronavirus       
      case 14:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-corona']);
        break;
      // seminarios       
      case 15:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/seminarios']);
        break;
    }
  }

  public GETDepartamentoFuncionario(Id) {
    this._DepartamentoService.GETDepartamentoFuncionario(Id).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
          });
          this.ListadoFuncionarios = datos;
          console.log(datos);
          sessionStorage.setItem("administrativo_listado", JSON.stringify(datos));
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  DetalleFuncionario(funcionario) {
    console.log(JSON.stringify(funcionario));
    sessionStorage.setItem("reload-academico_detalle", JSON.stringify(true));
    sessionStorage.setItem("academico_detalle", JSON.stringify(funcionario));
    this._Router.navigate(['/academico-detalle']);
  }

  public ApplyFilter(filterValue: string) {
    this.ListadoFuncionarios = this.ListadoFuncionariosInicial;
    this.ListadoFuncionarios = this.ListadoFuncionarios.filter(function (element) {
      return element.nombre.toLowerCase().indexOf(filterValue.trim().toLowerCase()) > -1;
    });
    
  }

  public Entrar(event) {
    let inputChar = event.charCode;
    if (inputChar == 13) {
      this.ApplyFilter(this.textoBusqueda)
    }
  }

}
