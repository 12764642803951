import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { CarreraService } from '../../services/carrera.service';
import { DepartamentoService } from '../../services/departamento.service';
import { TranslateService } from '@ngx-translate/core';
import { GlobalService } from '../../services/global.service';
import { SliderService } from '../../services/slider.service';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-academicos-carrera-listado',
  templateUrl: './academicos-carrera-listado.component.html',
  styleUrls: ['./academicos-carrera-listado.component.css']
})
export class AcademicosCarreraListadoComponent implements OnInit {

  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public activeLang = 'es';

  public departamento = {
    cod_departamento: 0,
    nombre: "",
    img: "",
    descripcion: "",
    web_oficial: "",
    tipo: 0,
    base64: "",
    cambio: 0
  };

  public textoBusqueda = "";
  public ListadoFuncionariosInicial: any;
  public ListadoFuncionarios: any;
  public Loading: boolean;

  public page_size = 6;
  public page_number = 1;
  public pageSizeOptions = [3, 6, 9, 18, 36];
  public largo_listado = 0;

  constructor(private _Router: Router, private _ActivatedRoute: ActivatedRoute, private _SliderService: SliderService, private _DepartamentoService: DepartamentoService, private _GlobalService: GlobalService,
    private _CarreraService: CarreraService, private translate: TranslateService) {
    this.reload();
    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);

    this.GETBanner();
  }

  public handlePage(e:PageEvent){
    this.page_size = e.pageSize;
    this.page_number = e.pageIndex + 1;
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-academico-listado"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-academico-listado", JSON.stringify(false));
      window.location.reload();
    }
  }

 public RedirectBanner(url){
    window.open(url);
  }

  public GETBanner() {
    this._SliderService.GETBannerId(2).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  ngOnInit() {
    this._ActivatedRoute.paramMap.subscribe(params => {
      if (params.has("id")) {
        let cod = params.get("id");
        this.Loading = !this.Loading;
        this._CarreraService.GetAcademicoCarrera(cod).subscribe(
          result => {
            if (result.status == 200) {
              let datos: any = (result.body);
              datos.forEach(element => {
                element["img"] = this._GlobalService.UrlArchivos() + element["img"];
              });
              this.ListadoFuncionariosInicial = datos;
              this.ListadoFuncionarios = datos;
              this.largo_listado = datos.length;
              console.log("Academicos carrera", datos);
              sessionStorage.setItem("academico_listado", JSON.stringify(datos));
            } else {
              console.log('result', result);
            }
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log('An error occurred:', err.error.message);
            }
          }
        );
      } else {
        this._Router.navigate(['/']);
      }
    });
  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  
  DetalleFuncionario(funcionario) {
    console.log(JSON.stringify(funcionario));
    if (funcionario.cod_academico == 30) {
      sessionStorage.setItem("reload-academico-detalle-ejemplo", JSON.stringify(true));
      this._Router.navigate(['/academico-detalle-ejemplo']);
    } else {
      sessionStorage.setItem("reload-academico-detalle", JSON.stringify(true));
      sessionStorage.setItem("academico_detalle", JSON.stringify(funcionario));
      this._Router.navigate(['/academico-detalle/' + funcionario.cod_academico]);
    }
  }

  public buscarEvento() {
    this.ApplyFilter(this.textoBusqueda);
  }

  public ApplyFilter(filterValue: string) {
    this.ListadoFuncionarios = this.ListadoFuncionariosInicial;
    this.ListadoFuncionarios = this.ListadoFuncionarios.filter(function (element) {
      return element.nombre.toLowerCase().indexOf(filterValue.trim().toLowerCase()) > -1;
    });

  }

  public Entrar(event) {
    let inputChar = event.charCode;
    if (inputChar == 13) {
      this.ApplyFilter(this.textoBusqueda)
    }
  }
}
