import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';

@Injectable({
    providedIn: 'root'
})
export class UsuarioService {
    private url: string;
    constructor(private _HttpClient: HttpClient,
        private _GlobalService: GlobalService) {
        this.url = _GlobalService.UrlApi();
    }


    public PostSuscripcion(Datos) {
        var urlFull = this.url + 'suscripcion';
        console.log(urlFull);
        return this._HttpClient.post(urlFull, Datos, { observe: 'response' });
    }

    public GETActividadesTipo() {
        var urlFull = this.url + 'actividadtipo';
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GETActividadesTipoId(id) {
        var urlFull = this.url + 'actividadtipo/'+id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GETActividadesVideoId(id) {
        var urlFull = this.url + 'actividadvideo/'+id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

}
