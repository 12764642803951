import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'contenidos-ubb';

  public ListadoContenidos: any;

  constructor() {
    sessionStorage.setItem("idioma", JSON.stringify("es"));
  }

}
