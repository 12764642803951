import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//Componets
import { InicioComponent } from './inicio/inicio.component';
import { NoticiasComponent } from './noticia/noticias/noticias.component';
import { DepartamentoComponent } from './departamento/departamento/departamento.component';
import { DepartamentoDetalleComponent } from './departamento/departamento-detalle/departamento-detalle.component';
import { InvestigacionComponent } from './investigacion/investigacion/investigacion.component';
import { ComisionesComponent } from './comisiones/comisiones.component';
import { VinculacionComponent } from './vinculacion/vinculacion/vinculacion.component';
import { NoticiasMasComponent } from './noticia/noticias-mas/noticias-mas.component';
import { NoticiaDetalleComponent } from './noticia/noticia-detalle/noticia-detalle.component';
import { NoticiaListadoComponent } from './noticia/noticia-listado/noticia-listado.component';
import { EventoDetalleComponent } from './evento/evento-detalle/evento-detalle.component';
import { EventoListadoComponent } from './evento/evento-listado/evento-listado.component';
import { PregradoListadoComponent } from './carreras/pregrado-listado/pregrado-listado.component';
import { PostgradoListadoComponent } from './carreras/postgrado-listado/postgrado-listado.component';
import { CarreraDetalleComponent } from './carreras/carrera-detalle/carrera-detalle.component';
import { FuncionarioListadoComponent } from './funcionario/funcionario-listado/funcionario-listado.component';
import { FuncionarioDetalleComponent } from './funcionario/funcionario-detalle/funcionario-detalle.component';
import { InvestigacionAreaComponent } from './investigacion/investigacion-area/investigacion-area.component';
import { InvestigacionDepartamentoComponent } from './investigacion/investigacion-departamento/investigacion-departamento.component';
import { FacultadHistoriaComponent } from './facultad/facultad-historia/facultad-historia.component';
import { FacultadMisionVisionComponent } from './facultad/facultad-mision-vision/facultad-mision-vision.component';
import { FacultadOrganigramaComponent } from './facultad/facultad-organigrama/facultad-organigrama.component';
import { AcademicoDetalleComponent } from './academico/academico-detalle/academico-detalle.component';
import { AcademicoListadoComponent } from './academico/academico-listado/academico-listado.component';
import { NoticiaBusquedaComponent } from './noticia/noticia-busqueda/noticia-busqueda.component';
import { EventoBusquedaComponent } from './evento/evento-busqueda/evento-busqueda.component';
import { DepartamentoPublicacionComponent } from './departamento/departamento-publicacion/departamento-publicacion.component';
import { FacultadDecanaturaComponent } from './facultad/facultad-decanatura/facultad-decanatura.component';
import { AcademicoDetalleEjemploComponent } from './academico/academico-detalle-ejemplo/academico-detalle-ejemplo.component';
import { PublicacionesTotalComponent } from './publicacion_info/publicaciones-total/publicaciones-total.component';
import { FacultadRecursosComponent } from './facultad/facultad-recursos/facultad-recursos.component';
import { AcademicoAlumnoComponent } from './academico/academico-alumno/academico-alumno.component';
import { VinculacionMedioComponent } from './vinculacion/vinculacion-medio/vinculacion-medio.component';
import { VinculacionVideoComponent } from './vinculacion/vinculacion-video/vinculacion-video.component';
import { NoticiaCarreraComponent } from './noticia/noticia-carrera/noticia-carrera.component';
import { EventoCarreraComponent } from './evento/evento-carrera/evento-carrera.component';
import { GaleriaCarreraComponent } from './carreras/galeria-carrera/galeria-carrera.component';
import { NoticiaVinculacionComponent } from './noticia/noticia-vinculacion/noticia-vinculacion.component';
import { EventoVinculacionComponent } from './evento/evento-vinculacion/evento-vinculacion.component';
import { FacultadRecursosEducacionComponent } from './facultad/facultad-recursos-educacion/facultad-recursos-educacion.component';
import { FacultadRecursosCoronaComponent } from './facultad/facultad-recursos-corona/facultad-recursos-corona.component';
import { FacultadRecursosEducacionMoodleComponent } from './facultad/facultad-recursos-educacion-moodle/facultad-recursos-educacion-moodle.component';
import { FacultadRecursosEducacionAdeccaComponent } from './facultad/facultad-recursos-educacion-adecca/facultad-recursos-educacion-adecca.component';
import { HexagonoBachilleratoComponent } from './hexagono/hexagono-bachillerato/hexagono-bachillerato.component';
import { HexagonoPregradoComponent } from './hexagono/hexagono-pregrado/hexagono-pregrado.component';
import { HexagonoDoctaradoComponent } from './hexagono/hexagono-doctarado/hexagono-doctarado.component';
import { HexagonoMagisterComponent } from './hexagono/hexagono-magister/hexagono-magister.component';
import { HexagonoAcademicoComponent } from './hexagono/hexagono-academico/hexagono-academico.component';
import { VinculacionTallerComponent } from './vinculacion/vinculacion-taller/vinculacion-taller.component';
import { FormularioComponent } from './formulario/formulario/formulario.component';
import { FormularioFrameComponent } from './formulario/formulario-frame/formulario-frame.component';
import { SeminariosComponent } from './seminarios/seminarios.component';


//Maqueta investigacion
import { InvestInicioComponent } from './investigacion/investigacion-maqueta/invest-inicio/invest-inicio.component';

//Vistas academicos 
import { InvestAcadPatriciaComponent } from './investigacion/investigacion-maqueta/cs_basicas/invest-acad-patricia/invest-acad-patricia.component';
import { InvestAcadGonzaloComponent } from './investigacion/investigacion-maqueta/cs_basicas/invest-acad-gonzalo/invest-acad-gonzalo.component';
import { InvestAcadCarlosComponent } from './investigacion/investigacion-maqueta/cs_basicas/invest-acad-carlos/invest-acad-carlos.component';
import { InvestAcadOscarComponent } from './investigacion/investigacion-maqueta/cs_basicas/invest-acad-oscar/invest-acad-oscar.component';
import { InvestAcadJuanComponent } from './investigacion/investigacion-maqueta/cs_basicas/invest-acad-juan/invest-acad-juan.component';
import { InvestAcadPatricioComponent } from './investigacion/investigacion-maqueta/cs_basicas/invest-acad-patricio/invest-acad-patricio.component';
import { InvestAcadEdgarComponent } from './investigacion/investigacion-maqueta/cs_basicas/invest-acad-edgar/invest-acad-edgar.component';
import { InvestAcadAndresComponent } from './investigacion/investigacion-maqueta/cs_basicas/invest-acad-andres/invest-acad-andres.component';
import { InvestAcadDanielaComponent } from './investigacion/investigacion-maqueta/cs_basicas/invest-acad-daniela/invest-acad-daniela.component';
import { InvestAcadCristianComponent } from './investigacion/investigacion-maqueta/cs_basicas/invest-acad-cristian/invest-acad-cristian.component';
import { InvestAcadEnriqueComponent } from './investigacion/investigacion-maqueta/cs_basicas/invest-acad-enrique/invest-acad-enrique.component';

//Vistas invetigacion fisica
import { InvestFisicaPublicComponent } from './investigacion/investigacion-maqueta/fisica/invest-fisica-public/invest-fisica-public.component';
import { InvestFisicaProyectFrComponent } from './investigacion/investigacion-maqueta/fisica/invest-fisica-proyect-fr/invest-fisica-proyect-fr.component';
import { InvestFisicaProyectExComponent } from './investigacion/investigacion-maqueta/fisica/invest-fisica-proyect-ex/invest-fisica-proyect-ex.component';
import { InvestFisicaProyectInComponent } from './investigacion/investigacion-maqueta/fisica/invest-fisica-proyect-in/invest-fisica-proyect-in.component';
import { InvestFisicaTesisMgComponent } from './investigacion/investigacion-maqueta/fisica/invest-fisica-tesis-mg/invest-fisica-tesis-mg.component';
import { InvestFisicaTesisDcComponent } from './investigacion/investigacion-maqueta/fisica/invest-fisica-tesis-dc/invest-fisica-tesis-dc.component';
import { AcademicosCarreraListadoComponent } from './carreras/academicos-carrera-listado/academicos-carrera-listado.component';
import { GaleriaHistoricoComponent } from './galeria/galeria-historico/galeria-historico.component';
import { LineasInvestigacionComponent } from './carreras/lineas-investigacion/lineas-investigacion.component';

import { ListadoLaboratoriosComponent } from './carreras/listado-laboratorios/listado-laboratorios.component';
import { LaboratorioDetalleComponent } from './carreras/laboratorio-detalle/laboratorio-detalle.component';

import { AlumnosCarreraListadoComponent } from './carreras/alumnos-carrera-listado/alumnos-carrera-listado.component';
import { AlumnoDetalleComponent } from './carreras/alumno-detalle/alumno-detalle.component';
import { ProyectosInvestigacionComponent } from './investigacion/proyectos-investigacion/proyectos-investigacion.component';
import { CentrosInvestigacionComponent } from './investigacion/centros-investigacion/centros-investigacion.component';
import { GruposInvestigacionComponent } from './investigacion/grupos-investigacion/grupos-investigacion.component';


const appRoutes: Routes = [
  { path: '', component: InicioComponent },
  { path: 'noticias', component: NoticiasComponent },
  { path: 'departamento', component: DepartamentoComponent },
  { path: 'departamento-detalle', component: DepartamentoDetalleComponent },
  { path: 'departamento-detalle/:id', component: DepartamentoDetalleComponent },
  { path: 'departamento-publicacion', component: DepartamentoPublicacionComponent },
  { path: 'departamento-publicacion/:id', component: DepartamentoPublicacionComponent },
  { path: 'pregrado-listado', component: PregradoListadoComponent },
  { path: 'postgrado-listado', component: PostgradoListadoComponent },
  { path: 'carrera-detalle', component: CarreraDetalleComponent },
  { path: 'carrera-detalle/:id', component: CarreraDetalleComponent },
  { path: 'academicos-carrera-listado/:id', component: AcademicosCarreraListadoComponent },
  { path: 'lineas-investigacion/:id', component: LineasInvestigacionComponent },
  
  { path: 'laboratorios/:id', component: ListadoLaboratoriosComponent },
  { path: 'laboratorio-detalle/:id', component: LaboratorioDetalleComponent },
  { path: 'alumnos-listado/:id', component: AlumnosCarreraListadoComponent },
  { path: 'alumno-detalle/:id', component: AlumnoDetalleComponent },

  { path: 'investigacion', component: InvestigacionComponent },
  { path: 'comisiones', component: ComisionesComponent },
  { path: 'vinculacion', component: VinculacionComponent },
  { path: 'vinculacion-medio', component: VinculacionMedioComponent },
  { path: 'vinculacion-video/:id', component: VinculacionVideoComponent },
  { path: 'vinculacion-taller', component: VinculacionTallerComponent },
  { path: 'noticias-mas', component: NoticiasMasComponent },
  { path: 'noticia-detalle', component: NoticiaDetalleComponent },
  { path: 'noticia-vinculacion', component: NoticiaVinculacionComponent },
  { path: 'noticia-detalle/:id', component: NoticiaDetalleComponent },
  { path: 'noticia-carrera/:id', component: NoticiaCarreraComponent },
  { path: 'noticia-listado', component: NoticiaListadoComponent },
  { path: 'noticia-busqueda', component: NoticiaBusquedaComponent },
  { path: 'galeria-carrera/:id', component: GaleriaCarreraComponent },
  { path: 'evento-vinculacion', component: EventoVinculacionComponent },
  { path: 'evento-carrera/:id', component: EventoCarreraComponent },
  { path: 'evento-detalle', component: EventoDetalleComponent },
  { path: 'evento-detalle/:id', component: EventoDetalleComponent },
  { path: 'evento-listado', component: EventoListadoComponent },
  { path: 'evento-busqueda', component: EventoBusquedaComponent },
  { path: 'funcionario-listado/:id', component: FuncionarioListadoComponent },
  { path: 'funcionario-detalle', component: FuncionarioDetalleComponent },
  { path: 'investigacion-area', component: InvestigacionAreaComponent },
  { path: 'investigacion-departamento', component: InvestigacionDepartamentoComponent },
  { path: 'facultad-historia', component: FacultadHistoriaComponent },
  { path: 'facultad-mision-vision', component: FacultadMisionVisionComponent },
  { path: 'facultad-decanatura', component: FacultadDecanaturaComponent },
  { path: 'facultad-organigrama', component: FacultadOrganigramaComponent },
  { path: 'facultad-recursos', component: FacultadRecursosComponent },
  { path: 'facultad-recursos-educacion', component: FacultadRecursosEducacionComponent},
  { path: 'facultad-recursos-educacion-adecca', component: FacultadRecursosEducacionAdeccaComponent},
  { path: 'facultad-recursos-educacion-moodle', component: FacultadRecursosEducacionMoodleComponent},
  { path: 'facultad-recursos-corona', component: FacultadRecursosCoronaComponent },
  { path: 'seminarios', component: SeminariosComponent },
  { path: 'academico-detalle', component: AcademicoDetalleComponent },
  { path: 'academico-detalle/:id', component: AcademicoDetalleComponent },
  { path: 'academico-listado', component: AcademicoListadoComponent },
  { path: 'academico-listado/:id', component: AcademicoListadoComponent },
  { path: 'academico-detalle-ejemplo', component: AcademicoDetalleEjemploComponent },
  { path: 'academico-alumno/:id', component: AcademicoAlumnoComponent },
  { path: 'publicaciones-total', component: PublicacionesTotalComponent },
  { path: 'hexagono-bachillerato', component: HexagonoBachilleratoComponent },
  { path: 'hexagono-pregrado', component: HexagonoPregradoComponent },
  { path: 'hexagono-doctorado', component: HexagonoDoctaradoComponent },
  { path: 'hexagono-magister', component: HexagonoMagisterComponent },
  { path: 'hexagono-academico', component: HexagonoAcademicoComponent },
  { path: 'formulario/:id', component: FormularioComponent },
  { path: 'formulario-frame/:id', component: FormularioFrameComponent },

  { path: 'investigacion-inicio', component: InvestInicioComponent },
  { path: 'proyectos-investigacion', component: ProyectosInvestigacionComponent },
  { path: 'centros-investigacion', component: CentrosInvestigacionComponent },
  { path: 'grupos-investigacion', component: GruposInvestigacionComponent },

  { path: 'investigacion/csbasicas/patricia', component: InvestAcadPatriciaComponent },
  { path: 'investigacion/csbasicas/gonzalo', component: InvestAcadGonzaloComponent },
  { path: 'investigacion/csbasicas/carlos', component: InvestAcadCarlosComponent },
  { path: 'investigacion/csbasicas/oscar', component: InvestAcadOscarComponent },
  { path: 'investigacion/csbasicas/juan', component: InvestAcadJuanComponent },
  { path: 'investigacion/csbasicas/patricio', component: InvestAcadPatricioComponent },
  { path: 'investigacion/csbasicas/edgar', component: InvestAcadEdgarComponent },
  { path: 'investigacion/csbasicas/andres', component: InvestAcadAndresComponent },
  { path: 'investigacion/csbasicas/daniela', component: InvestAcadDanielaComponent },
  { path: 'investigacion/csbasicas/cristian', component: InvestAcadCristianComponent },
  { path: 'investigacion/csbasicas/enrique', component: InvestAcadEnriqueComponent },

  { path: 'investigacion/fisica/publicacion', component: InvestFisicaPublicComponent },
  { path: 'investigacion/fisica/proyectosfr', component: InvestFisicaProyectFrComponent },
  { path: 'investigacion/fisica/proyectosexternos', component: InvestFisicaProyectExComponent },
  { path: 'investigacion/fisica/proyectosinternos', component: InvestFisicaProyectInComponent },
  { path: 'investigacion/fisica/tesismagister', component: InvestFisicaTesisMgComponent },
  { path: 'investigacion/fisica/tesisdoctorado', component: InvestFisicaTesisDcComponent },

  { path: 'galeria-historico', component: GaleriaHistoricoComponent }
];

export const Routing: ModuleWithProviders = RouterModule.forRoot(
  appRoutes,
  { useHash: true  , scrollPositionRestoration: 'enabled' }//<-- para poder actualizar el navegador (prd)
);