import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';

@Injectable({
    providedIn: 'root'
})
export class ContenidoService {
    private url: string;
    constructor(private _HttpClient: HttpClient,
        private _GlobalService: GlobalService) {
        this.url = _GlobalService.UrlApi();
    }

    public GETContenido() {
        var urlFull = this.url + 'contenido';
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }
}
