import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http';
import { GlobalService } from './global.service';

@Injectable({
    providedIn: 'root'
})
export class CarreraService {
    private url: string;
    constructor(private _HttpClient: HttpClient,
        private _GlobalService: GlobalService) {
        this.url = _GlobalService.UrlApi();
    }

    public GetCarreraExtra(Id) {
        var urlFull = this.url + 'carreraextra/' + Id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GETCarrera(tipo) {
        var urlFull = this.url + 'carrera/tipo/'+tipo;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GETCarreraID(id) {
        var urlFull = this.url + 'carreraob/'+id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GetCarreraDetalle(Id) {
        var urlFull = this.url + 'carreradetalle/' + Id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GetCarreraContacto(Id) {
        var urlFull = this.url + 'carreracontacto/' + Id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GetCarreraContactoDirector(Id) {
        var urlFull = this.url + 'carreracontactodirector/' + Id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GetCarreraGaleria(Id) {
        var urlFull = this.url + 'carreragaleria/' + Id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GETCarreraTotal() {
        var urlFull = this.url + 'carrera';
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GetAcademicoCarrera(Id) {
        var urlFull = this.url + 'academicocarrera/' + Id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GetLineaInvestigacion(Id) {
        var urlFull = this.url + 'carrera_linea_investigacion/' + Id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GetLaboratorios(Id) {
        var urlFull = this.url + 'carrera_laboratorio/' + Id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GetLaboratoriosId(Id) {
        var urlFull = this.url + 'carrera_laboratorio/id/' + Id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GetLaboratorioImagenes(Id) {
        var urlFull = this.url + 'carrera_laboratorio_imagenes/' + Id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }


    public GetAlumnosCarrera(Id) {
        var urlFull = this.url + 'carrera_alumnos/' + Id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GetAlumnosCarreraId(Id) {
        var urlFull = this.url + 'carrera_alumnos/id/' + Id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GetAlumnoCurriculum(Id) {
        var urlFull = this.url + 'carrera_alumnos_curriculum/'+Id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    public GetAlumnoCurriculumTipo(id,tipo) {
        var urlFull = this.url + 'carrera_alumnos_curriculum/'+id+'/'+tipo;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }

    // permisos funcionalidades 

    public GetFuncionalidadesCarrera(id) {
        var urlFull = this.url + 'carrera_funcionalidades/'+id;
        console.log(urlFull);
        return this._HttpClient.get(urlFull, { observe: 'response' });
    }


}
