import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {
  private url: string;
  constructor(public _MatSnackBar: MatSnackBar) {
    this.url = 'https://ciencias.ubiobio.cl/web/';
    // this.url = 'http://localhost:8181/';
  }

  public UrlBase(): string {
    return this.url;
  }

  public UrlApi(): string {

    // return this.url + 'api_ubb2/public/api/';
    return this.url + 'public/index.php/api/';
  }

  //p

  public UrlArchivos(): string {
    // return this.url + 'api_ubb2/src/archivos/';
    return this.url + 'src/archivos/';
    // return 'src/archivos/';
  }

  public ToastOk() {
    this._MatSnackBar.open('Solicitud procesada correctamente.', "", { duration: 5000, panelClass: 'alert-succes' });
  }
  public ToastError() {
    this._MatSnackBar.open('Lo sentimos no pudimos completar tu solicitud, inténtalo de nuevo más tarde.', "", { duration: 5000, panelClass: 'alert-danger' });
  }
  public ToastNoDatos() {
    this._MatSnackBar.open('No hay datos para mostrar.', "", { duration: 5000, panelClass: 'alert-info' });
  }

  public ToastErrorMensaje(Mensaje: string) {
    this._MatSnackBar.open(Mensaje, "", { duration: 5000, panelClass: 'alert-danger' });
  }

  public ToastInfo(Mensaje: string) {
    this._MatSnackBar.open(Mensaje, "", { duration: 5000, panelClass: 'alert-info' });
  }
  public ToastCampoRequerido(Campo: string) {
    this._MatSnackBar.open('Campo obligatirio: *' + Campo, "", { duration: 3000, panelClass: 'alert-info' });
  }
}
