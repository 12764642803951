import { Component, OnInit } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { GlobalService } from '../../services/global.service';
import { CarreraService } from '../../services/carrera.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DepartamentoService } from '../../services/departamento.service';
import { SliderService } from '../../services/slider.service';
import { NoticiaService } from '../../services/noticia.service';
import { EventoService } from '../../services/evento.service';

@Component({
  selector: 'app-listado-laboratorios',
  templateUrl: './listado-laboratorios.component.html',
  styleUrls: ['./listado-laboratorios.component.css']
})
export class ListadoLaboratoriosComponent implements OnInit {

  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public activeLang = 'es';

  public carrera_extra = { cod_extra: 0, cod_carrera: 0, descripcion: "" };

  public carrera = {
    cod_carrera: 0,
    nombre: "",
    nombre_ingles: "",
    img: "",
    tipo: 1,
    web_oficial: "",
    face: "",
    insta: "",
    logo: "",
    base64_logo: "",
    cambio_logo: 0,
    base64: "",
    cambio: 0
  };

  
  public laboratorios: any;

  public Loading: boolean;


  constructor(private _Router: Router, private _ActivatedRoute: ActivatedRoute, private _SliderService: SliderService, private _CarreraService: CarreraService, private _GlobalService: GlobalService, private _DepartamentoService: DepartamentoService,
    private _NoticiaService: NoticiaService, private _EventoService: EventoService, private translate: TranslateService) {
    this.reload();
    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-carrera-detalle"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-carrera-detalle", JSON.stringify(false));
      window.location.reload();
    }
  }

  ngOnInit() {
    this._ActivatedRoute.paramMap.subscribe(params => {
      if (params.has("id")) {
        let cod = params.get("id");
        // console.log("Codigo super sutro", cod);
        this._CarreraService.GETCarreraID(cod).subscribe(
          result => {
            if (result.status == 200) {
              let datos: any = (result.body);
              console.log("Carrera", datos);
              if (datos != null) {
                // let img_prueba = "src/archivos/" + datos["img"];
                datos["nombre"] = datos["nombre"].toUpperCase();
                datos["img"] = this._GlobalService.UrlArchivos() + datos["img"];
                datos["logo"] = this._GlobalService.UrlArchivos() + datos["logo"];

                this.carrera = datos;
                
                this.GetLaboratorios();
              }
            } else {
              console.log('result', result);
            }
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log('An error occurred:', err.error.message);
            }
          }
        );

      } else {
        this._Router.navigate(['/']);
      }
    });
  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  public GetLaboratorios() {
    this.Loading = !this.Loading;
    this._CarreraService.GetLaboratorios(this.carrera.cod_carrera).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          this.laboratorios = datos;

          console.log('laboratorios', datos);

        } else {
          console.log('result', result)
        }
        this.Loading = !this.Loading;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
        this.Loading = !this.Loading;
      }
    );
  }

  public RedireccionarLab(cod_laboratorio){
    console.log("cod_laboratorio",cod_laboratorio);
    this._Router.navigate(['/laboratorio-detalle/' + cod_laboratorio]);
  }

}
