import { Component, OnInit } from '@angular/core';
import { NoticiaService } from '../../services/noticia.service';
import { GlobalService } from '../../services/global.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CarreraService } from '../../services/carrera.service';
import { DepartamentoService } from '../../services/departamento.service';
import { SliderService } from '../../services/slider.service';

@Component({
  selector: 'app-noticia-detalle',
  templateUrl: './noticia-detalle.component.html',
  styleUrls: ['./noticia-detalle.component.css']
})
export class NoticiaDetalleComponent implements OnInit {
  public descface = "http://ciencias.ubiobio.cl/web/src/archivos/PATRICIO%20N1589814126240.jpg";

  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public activeLang = 'es';

  public noticia: any;
  public noticia_detalle = {
    cod_detalle: 0,
    cod_noticia: 0,
    titulo: "",
    subtitulo: "",
    descripcion: "",
    descripcion_ingles: "",
    link: "",
    img: "",
    base64: "",
    tipo: 0
  };

  public Noticias = {
    noticia1: {},
    noticia2: {},
    noticia3: {},
  }

  public Loading: boolean;

  constructor(private _NoticiaService: NoticiaService, private _ActivatedRoute: ActivatedRoute, private _SliderService: SliderService, private _GlobalService: GlobalService, private _Router: Router, private _DepartamentoService: DepartamentoService,
    private _CarreraService: CarreraService, private translate: TranslateService) {
    // this.reload();

    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);
    // this.Loading = !this.Loading;
    // let timer = setInterval(() => {
    //   this.Loading = !this.Loading;
    //   clearInterval(timer);
    // }, 1500);
    // let info = JSON.parse(sessionStorage.getItem("noticia_detalle"));
    // let listado = JSON.parse(sessionStorage.getItem("noticia_listado"));
    // this.noticia = info.Noticia;
    // console.log(this.noticia);
    // this.OrdenarNoticias(listado);
    this.ListadoBanner = [];
    // this.GetNoticiaDetalle();
  }


  ngOnInit() {

    this._ActivatedRoute.paramMap.subscribe(params => {
      if (params.has("id")) {
        let cod = params.get("id");
        this.Loading = !this.Loading;
        this._NoticiaService.GETNoticiaId(cod).subscribe(
          result => {
            if (result.status == 200) {
              let datos: any = (result.body);
              this.noticia = datos;
              this.noticia.img = this._GlobalService.UrlArchivos() + this.noticia.img;
              console.log('Noticia', JSON.stringify(datos));
              this.GETNoticiaDetalle(cod);
              this.GETBanner();
              this.GETNoticia();

            } else {
              console.log('result', result)
            }
            this.Loading = !this.Loading;
          },
          (err: HttpErrorResponse) => {
            if (err.error instanceof Error) {
              console.log('An error occurred:', err.error.message);
            }
            this.Loading = !this.Loading;
          }
        );
      } else {
        this._Router.navigate(['/']);
      }
    });
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-noticia-detalle"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-noticia-detalle", JSON.stringify(false));
      window.location.reload();
    }
  }

  public RedirectBanner(url) {
    window.open(url);
  }

  public GETNoticiaDetalle(cod) {
    this._NoticiaService.GETNoticiaDetalle(cod).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          this.noticia_detalle = datos;
          this.noticia_detalle.base64 = this._GlobalService.UrlArchivos() + this.noticia_detalle.base64;
          this.noticia_detalle.cod_noticia = this.noticia.cod_noticia;
          for (let index = 0; index < 100; index++) {
            this.noticia_detalle.descripcion = this.noticia_detalle.descripcion.replace('class="ql-font-BAHNSCHRIFT"', 'class="display-5"');
            this.noticia_detalle.descripcion = this.noticia_detalle.descripcion.replace('<p class="ql-align-justify">', '<p class="display-5 ql-align-justify">');

            this.noticia_detalle.descripcion = this.noticia_detalle.descripcion.replace('<span s', '<span class="display-5"  align="justify" s');
            this.noticia_detalle.descripcion = this.noticia_detalle.descripcion.replace('<span style="background-color: transparent;">', '<span class="display-5" align="justify" style="background-color: transparent;">');

            this.noticia_detalle.descripcion = this.noticia_detalle.descripcion.replace('<span style="background-color: transparent; color: rgb(0, 0, 0);">', '<span class="display-5" align="justify">');
            this.noticia_detalle.descripcion = this.noticia_detalle.descripcion.replace('<strong style="background-color: transparent; color: rgb(0, 0, 0);">', '<strong class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" style="background-color: transparent; color: rgb(0, 0, 0);">');
            this.noticia_detalle.descripcion = this.noticia_detalle.descripcion.replace('<h1>', '<h1 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">');
            this.noticia_detalle.descripcion = this.noticia_detalle.descripcion.replace('<li>', '<li class="display-5" align="justify">');
            this.noticia_detalle.descripcion = this.noticia_detalle.descripcion.replace('<p>', '<p class="display-5" align="justify">');
            this.noticia_detalle.descripcion = this.noticia_detalle.descripcion.replace('<h1', '<h3');
            this.noticia_detalle.descripcion = this.noticia_detalle.descripcion.replace('h1>', 'h3>');
            this.noticia_detalle.descripcion = this.noticia_detalle.descripcion.replace('<strong', '<h3');
            this.noticia_detalle.descripcion = this.noticia_detalle.descripcion.replace('strong>', 'h3>');
            this.noticia_detalle.descripcion = this.noticia_detalle.descripcion.replace('<h3>', '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">');
            this.noticia_detalle.descripcion = this.noticia_detalle.descripcion.replace('<h3 s', '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" s');
            //descripción en ingles
            this.noticia_detalle.descripcion_ingles = this.noticia_detalle.descripcion_ingles.replace('class="ql-font-BAHNSCHRIFT"', 'class="display-5"');
            this.noticia_detalle.descripcion_ingles = this.noticia_detalle.descripcion_ingles.replace('<p class="ql-align-justify">', '<p class="display-5 ql-align-justify">');

            this.noticia_detalle.descripcion_ingles = this.noticia_detalle.descripcion_ingles.replace('<span s', '<span class="display-5"  align="justify" s');
            this.noticia_detalle.descripcion_ingles = this.noticia_detalle.descripcion_ingles.replace('<span style="background-color: transparent;">', '<span class="display-5" align="justify" style="background-color: transparent;">');

            this.noticia_detalle.descripcion_ingles = this.noticia_detalle.descripcion_ingles.replace('<span style="background-color: transparent; color: rgb(0, 0, 0);">', '<span class="display-5" align="justify">');
            this.noticia_detalle.descripcion_ingles = this.noticia_detalle.descripcion_ingles.replace('<strong style="background-color: transparent; color: rgb(0, 0, 0);">', '<strong class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" style="background-color: transparent; color: rgb(0, 0, 0);">');
            this.noticia_detalle.descripcion_ingles = this.noticia_detalle.descripcion_ingles.replace('<h1>', '<h1 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">');
            this.noticia_detalle.descripcion_ingles = this.noticia_detalle.descripcion_ingles.replace('<li>', '<li class="display-5" align="justify">');
            this.noticia_detalle.descripcion_ingles = this.noticia_detalle.descripcion_ingles.replace('<p>', '<p class="display-5" align="justify">');
            this.noticia_detalle.descripcion_ingles = this.noticia_detalle.descripcion_ingles.replace('<h1', '<h3');
            this.noticia_detalle.descripcion_ingles = this.noticia_detalle.descripcion_ingles.replace('h1>', 'h3>');
            this.noticia_detalle.descripcion_ingles = this.noticia_detalle.descripcion_ingles.replace('<strong', '<h3');
            this.noticia_detalle.descripcion_ingles = this.noticia_detalle.descripcion_ingles.replace('strong>', 'h3>');
            this.noticia_detalle.descripcion_ingles = this.noticia_detalle.descripcion_ingles.replace('<h3>', '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">');
            this.noticia_detalle.descripcion_ingles = this.noticia_detalle.descripcion_ingles.replace('<h3 class="ql-size-huge">', '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong">');
            this.noticia_detalle.descripcion_ingles = this.noticia_detalle.descripcion_ingles.replace('<h3 s', '<h3 class="mbr-section-title pb-3 mbr-fonts-style display-2 formatoletrastrong" s');
            this.noticia_detalle.descripcion_ingles = this.noticia_detalle.descripcion_ingles.replace('<pre class=\"ql-syntax\" spellcheck=\"false\">', '<p class="display-5" align="justify">');
            this.noticia_detalle.descripcion_ingles = this.noticia_detalle.descripcion_ingles.replace('</pre>', '</p>');
          }
          console.log('Noticia Detalle', JSON.stringify(datos));
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public GETBanner() {
    this._SliderService.GETBannerId(7).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  public GETNoticia() {
    this._NoticiaService.GETNoticia().subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
          });
          this.OrdenarNoticias(datos);
          // this.ListadoNoticias = datos;
          // sessionStorage.setItem("noticia_listado", JSON.stringify(datos));
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }



  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  //Rutas
  public Redireccionar(tipo, cod) {
    switch (tipo) {
      case 1:
        this._Router.navigate(['/departamento-detalle/' + cod]);
        break;
      case 2:
        this._Router.navigate(['/carrera-detalle/' + cod]);
        break;
      // facultad-historia
      case 3:
        sessionStorage.setItem("reload-facultad-historia", JSON.stringify(true));
        this._Router.navigate(['/facultad-historia']);
        break;
      // facultad-mision-vision        
      case 4:
        sessionStorage.setItem("reload-facultad-mision-vision", JSON.stringify(true));
        this._Router.navigate(['/facultad-mision-vision']);
        break;
      // facultad-organigrama        
      case 5:
        sessionStorage.setItem("reload-facultad-organigrama", JSON.stringify(true));
        this._Router.navigate(['/facultad-organigrama']);
        break;
      // vinculacion        
      case 6:
        sessionStorage.setItem("reload-vinculacion", JSON.stringify(true));
        this._Router.navigate(['/vinculacion']);
        break;
      // departamento        
      case 7:
        sessionStorage.setItem("reload-departamento", JSON.stringify(true));
        this._Router.navigate(['/departamento']);
        break;
      // pregrado-listado        
      case 8:
        sessionStorage.setItem("reload-pregrado-listado", JSON.stringify(true));
        this._Router.navigate(['/pregrado-listado']);
        break;
      // postgrado-listado        
      case 9:
        sessionStorage.setItem("reload-postgrado-listado", JSON.stringify(true));
        this._Router.navigate(['/postgrado-listado']);
        break;
      // investigacion        
      case 10:
        sessionStorage.setItem("reload-investigacion", JSON.stringify(true));
        this._Router.navigate(['/investigacion-inicio']);
        break;
      // recursos        
      case 11:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos']);
        break;
      // viculacion medio        
    case 12:
        sessionStorage.setItem("reload-vinculacion-medio", JSON.stringify(true));
        this._Router.navigate(['/vinculacion-medio']);
        break;
      // recursos educacion        
      case 13:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-educacion']);
        break;
      // recursos coronavirus       
      case 14:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-corona']);
        break;
      // seminarios       
      case 15:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/seminarios']);
        break;
    }
  }

  public GetNoticiaDetalle() {
    this.Loading = !this.Loading;
    this._NoticiaService.GETNoticiaDetalle(this.noticia.cod_noticia).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          this.noticia_detalle = datos;
          this.noticia_detalle.base64 = this._GlobalService.UrlArchivos() + this.noticia_detalle.base64;
          this.noticia_detalle.cod_noticia = this.noticia.cod_noticia;
          console.log('Noticia Detalle', JSON.stringify(datos));

        } else {
          console.log('result', result)
        }
        this.Loading = !this.Loading;
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
        this.Loading = !this.Loading;
      }
    );
  }

  public OrdenarNoticias(datos) {
    for (let i = 0; i < datos.length; i++) {
      switch (i + 1) {
        case 1:
          this.Noticias.noticia1 = datos[i];
          break;
        case 2:
          this.Noticias.noticia2 = datos[i];
          break;
        case 3:
          this.Noticias.noticia3 = datos[i];
          break;
      }
      if (i == 2) {
        break;
      }
    }
  }

  public DetalleNoticias(noticia) {
    let info = {
      Noticia: noticia
    };
    sessionStorage.setItem("noticia_detalle", JSON.stringify(info));

    this.noticia = noticia;
    this.GetNoticiaDetalle();
  }

  public CompartirFacebook() {

    let urlpag = "http%3A%2F%2Fciencias.ubiobio.cl%2Fweb%2F%23%2Fnoticia-detalle%2F" + this.noticia.cod_noticia;
    // let titulo = "prueba";
    // let descripcion = "descrip"
    // let imagen = this.noticia_detalle.base64;

    // document.title

    // https://www.facebook.com/sharer.php?caption=[caption]&description=[description]&u=[website]&picture=[image-url]

    // let pag = "https://www.facebook.com/sharer/sharer.php?description="+descripcion+"&picture=" + imagen;

    // console.log(pag);
    // let url = 'https://work.facebook.com/sharer.php?display=popup&u=' + urlpag;
    // let options = 'toolbar=0,status=0,resizable=1,width=626,height=436';
    // window.open(url, 'sharer', options);
    let w = 600;
    let h = 300;
    var left = (screen.width/2)-(w/2);
    var top = (screen.height/2)-(h/2);

    window.open("https://www.facebook.com/sharer/sharer.php?u=" + urlpag , this.noticia_detalle.titulo , 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no,resizable=yes, width='+w+', height='+h+', top='+top+', left='+left); return false;
  }

}
