import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../services/global.service';
import { HexagonoService } from '../services/hexagono.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DepartamentoService } from '../services/departamento.service';
import { TranslateService } from '@ngx-translate/core';
import { CarreraService } from '../services/carrera.service';
import { SliderService } from '../services/slider.service';
import { UsuarioService } from '../services/usuario.service';
declare var VanillaTilt;


@Component({
  selector: 'app-ci2030',
  templateUrl: './ci2030.component.html',
  styleUrls: ['./ci2030.component.css']
})
export class Ci2030Component implements OnInit {

 
  public ListadoBanner: any;
  public ListadoActividades: any;
  public InicioBanner = "";
  public URLBanner = "";

  public activeLang = 'es';


  public ListadoHexagono: any;
  public Loading: boolean;
  public imgPrincipal = "assets/imgs/historia/facultad.png";

  public img_1 = "assets/imgs/corona/BANNER COVID ESTADISTICA.png";
  public link_1 = "http://estadistica.ubiobio.cl/?page_id=176";

  public img_2 = "assets/imgs/corona/BANNER COVID UFI-07.png";
  public link_2 = "http://ufi.ubiobio.cl/web/index.php/site/guia/16";

  public img_3 = "assets/imgs/corona/BANNER ENLACE UBBCOVID-07.png";
  public link_3 = "http://ubiobio.cl/covid19/";

  public img_4 = "assets/imgs/corona/BANNER RECURSOS COVID-06.png";
  public link_4 = "assets/imgs/corona/GUIA QUIMICA DESINFECTANTES DE SUPERFICIES.pdf";



  constructor(private _Router: Router, private _GlobalService: GlobalService, private _SliderService: SliderService, private _HexagonoService: HexagonoService, private _DepartamentoService: DepartamentoService,
    private _CarreraService: CarreraService, private translate: TranslateService, private _UsuarioService: UsuarioService) {
    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);

  }



  
  ngOnInit() {

    VanillaTilt.init(document.querySelector(".tilt-image"), { max: 25, speed: 400 });

    VanillaTilt.init(document.querySelectorAll(".tilt-image"));
  }

  //Rutas
  public Redireccionar(tipo, cod) {
    switch (tipo) {
      case 1:
        this._Router.navigate(['/departamento-detalle/' + cod]);
        break;
      case 2:
        this._Router.navigate(['/carrera-detalle/' + cod]);
        break;
      // facultad-historia
      case 3:
        sessionStorage.setItem("reload-facultad-historia", JSON.stringify(true));
        this._Router.navigate(['/facultad-historia']);
        break;
      // facultad-mision-vision        
      case 4:
        sessionStorage.setItem("reload-facultad-mision-vision", JSON.stringify(true));
        this._Router.navigate(['/facultad-mision-vision']);
        break;
      // facultad-organigrama        
      case 5:
        sessionStorage.setItem("reload-facultad-organigrama", JSON.stringify(true));
        this._Router.navigate(['/facultad-organigrama']);
        break;
      // vinculacion        
      case 6:
        sessionStorage.setItem("reload-vinculacion", JSON.stringify(true));
        this._Router.navigate(['/vinculacion']);
        break;
      // departamento        
      case 7:
        sessionStorage.setItem("reload-departamento", JSON.stringify(true));
        this._Router.navigate(['/departamento']);
        break;
      // pregrado-listado        
      case 8:
        sessionStorage.setItem("reload-pregrado-listado", JSON.stringify(true));
        this._Router.navigate(['/pregrado-listado']);
        break;
      // postgrado-listado        
      case 9:
        sessionStorage.setItem("reload-postgrado-listado", JSON.stringify(true));
        this._Router.navigate(['/postgrado-listado']);
        break;
      // investigacion        
      case 10:
        sessionStorage.setItem("reload-investigacion", JSON.stringify(true));
        this._Router.navigate(['/investigacion2']);
        break;
      // recursos        
      case 11:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos']);
        break;
      // viculacion medio        
      case 12:
        sessionStorage.setItem("reload-vinculacion-medio", JSON.stringify(true));
        this._Router.navigate(['/vinculacion-medio']);
        break;
      // recursos educacion        
      case 13:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-educacion']);
        break;
     // recursos coronavirus       
      case 14:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-corona']);
        break;
      // ci2030       
      case 15:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/ci2030']);
        break;
    }
  }

}
