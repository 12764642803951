import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  public activeLang = 'es';

  constructor(private _Router: Router, private translate: TranslateService) {
    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);
  }

  ngOnInit(): void {
  }

  public Redireccionar(tipo, cod) {
    switch (tipo) {
      case 1:
        this._Router.navigate(['/departamento-detalle/' + cod]);
        break;
      case 2:
        this._Router.navigate(['/carrera-detalle/' + cod]);
        break;
      // facultad-historia
      case 3:
        sessionStorage.setItem("reload-facultad-historia", JSON.stringify(true));
        this._Router.navigate(['/facultad-historia']);
        break;
      // facultad-mision-vision        
      case 4:
        sessionStorage.setItem("reload-facultad-mision-vision", JSON.stringify(true));
        this._Router.navigate(['/facultad-mision-vision']);
        break;
      // facultad-organigrama        
      case 5:
        sessionStorage.setItem("reload-facultad-organigrama", JSON.stringify(true));
        this._Router.navigate(['/facultad-organigrama']);
        break;
      // vinculacion        
      case 6:
        sessionStorage.setItem("reload-vinculacion", JSON.stringify(true));
        this._Router.navigate(['/vinculacion']);
        break;
      // departamento        
      case 7:
        sessionStorage.setItem("reload-departamento", JSON.stringify(true));
        this._Router.navigate(['/departamento']);
        break;
      // pregrado-listado        
      case 8:
        sessionStorage.setItem("reload-pregrado-listado", JSON.stringify(true));
        this._Router.navigate(['/pregrado-listado']);
        break;
      // postgrado-listado        
      case 9:
        sessionStorage.setItem("reload-postgrado-listado", JSON.stringify(true));
        this._Router.navigate(['/postgrado-listado']);
        break;
      // investigacion        
      case 10:
        sessionStorage.setItem("reload-investigacion", JSON.stringify(true));
        this._Router.navigate(['/investigacion-inicio']);
        break;
      // recursos        
      case 11:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos']);
        break;
      // viculacion medio        
      case 12:
        sessionStorage.setItem("reload-vinculacion-medio", JSON.stringify(true));
        this._Router.navigate(['/vinculacion-medio']);
        break;
      // recursos educacion        
      case 13:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-educacion']);
        break;
      // recursos coronavirus       
      case 14:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-corona']);
        break;
      // seminarios       
      case 15:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/seminarios']);
        break;
    }
  }


}
