import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../../../services/global.service';
import { DepartamentoService } from '../../../../services/departamento.service';
import { TranslateService } from '@ngx-translate/core';
import { CarreraService } from '../../../../services/carrera.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SliderService } from '../../../../services/slider.service';

@Component({
  selector: 'app-invest-acad-edgar',
  templateUrl: './invest-acad-edgar.component.html',
  styleUrls: ['./invest-acad-edgar.component.css']
})
export class InvestAcadEdgarComponent implements OnInit {
  public ListadoBanner: any;
  public InicioBanner = "";
  public URLBanner = "";
  public activeLang = 'es';
  public imgPrincipal = "assets/imgs/historia/facultad.png";

  public matematica: boolean;
  public quimica: boolean;
  public fisica: boolean;
  public estadistica: boolean;
  public cs_basicas: boolean;

  constructor(private _Router: Router, private _GlobalService: GlobalService, private _SliderService: SliderService, private _DepartamentoService: DepartamentoService,
    private _CarreraService: CarreraService, private translate: TranslateService) {
    this.reload();

    this.matematica = true;
    this.quimica = false;
    this.fisica = false;
    this.estadistica = false;
    this.cs_basicas = false;

    this.activeLang = JSON.parse(sessionStorage.getItem("idioma"));
    this.translate.setDefaultLang(this.activeLang);
    this.ListadoBanner = [];
    this.GETBanner();
  }

  public reload() {
    let r = JSON.parse(sessionStorage.getItem("reload-facultad-mision-vision"));
    console.log("reload", r);
    if (r == true) {
      sessionStorage.setItem("reload-facultad-mision-vision", JSON.stringify(false));
      window.location.reload();
    }
  }

  public RedirectBanner(url) {
    window.open(url);
  }

  public GETBanner() {
    this._SliderService.GETBannerId(2).subscribe(
      result => {
        if (result.status == 200) {
          let datos: any = (result.body);
          let listado = [];
          let i = 0;
          datos.forEach(element => {
            element["img"] = this._GlobalService.UrlArchivos() + element["img"];
            if (i == 0) {
              this.InicioBanner = element["img"];
              this.URLBanner = element["url"];
            } else {
              listado.push(element);
            }
            i++;
          });
          this.ListadoBanner = listado;
          console.log(datos);
        } else {
          console.log('result', result);
        }
      },
      (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('An error occurred:', err.error.message);
        }
      }
    );
  }

  ngOnInit() {

  }

  public cambiarLenguaje(lang) {
    this.activeLang = lang;
    sessionStorage.setItem("idioma", JSON.stringify(lang));
    this.translate.use(lang);
  }

  //Rutas
  public Redireccionar(tipo, cod) {
    switch (tipo) {
      case 1:
        this._Router.navigate(['/departamento-detalle/' + cod]);
        break;
      case 2:
        this._Router.navigate(['/carrera-detalle/' + cod]);
        break;
      // facultad-historia
      case 3:
        sessionStorage.setItem("reload-facultad-historia", JSON.stringify(true));
        this._Router.navigate(['/facultad-historia']);
        break;
      // facultad-mision-vision        
      case 4:
        sessionStorage.setItem("reload-facultad-mision-vision", JSON.stringify(true));
        this._Router.navigate(['/facultad-mision-vision']);
        break;
      // facultad-organigrama        
      case 5:
        sessionStorage.setItem("reload-facultad-organigrama", JSON.stringify(true));
        this._Router.navigate(['/facultad-organigrama']);
        break;
      // vinculacion        
      case 6:
        sessionStorage.setItem("reload-vinculacion", JSON.stringify(true));
        this._Router.navigate(['/vinculacion']);
        break;
      // departamento        
      case 7:
        sessionStorage.setItem("reload-departamento", JSON.stringify(true));
        this._Router.navigate(['/departamento']);
        break;
      // pregrado-listado        
      case 8:
        sessionStorage.setItem("reload-pregrado-listado", JSON.stringify(true));
        this._Router.navigate(['/pregrado-listado']);
        break;
      // postgrado-listado        
      case 9:
        sessionStorage.setItem("reload-postgrado-listado", JSON.stringify(true));
        this._Router.navigate(['/postgrado-listado']);
        break;
      // investigacion        
      case 10:
        sessionStorage.setItem("reload-investigacion", JSON.stringify(true));
        this._Router.navigate(['/investigacion-inicio']);
        break;
      // recursos        
      case 11:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos']);
        break;
      // viculacion medio        
      case 12:
        sessionStorage.setItem("reload-vinculacion-medio", JSON.stringify(true));
        this._Router.navigate(['/vinculacion-medio']);
        break;
      // recursos educacion        
      case 13:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-educacion']);
        break;
      // recursos coronavirus       
      case 14:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/facultad-recursos-corona']);
        break;
      // seminarios       
      case 15:
        sessionStorage.setItem("reload-recursos", JSON.stringify(true));
        this._Router.navigate(['/seminarios']);
        break;
    }
  }

  public InfoDepto(tipo) {
    switch (tipo) {
      case 1:
        this.matematica = true;
        this.quimica = false;
        this.fisica = false;
        this.estadistica = false;
        this.cs_basicas = false;
        break;
      case 2:
        this.matematica = false;
        this.quimica = true;
        this.fisica = false;
        this.estadistica = false;
        this.cs_basicas = false;
        break;
      case 3:
        this.matematica = false;
        this.quimica = false;
        this.fisica = true;
        this.estadistica = false;
        this.cs_basicas = false;
        break;
      case 4:
        this.matematica = false;
        this.quimica = false;
        this.fisica = false;
        this.estadistica =  true;
        this.cs_basicas = false;
        break;
      case 5:
        this.matematica = false;
        this.quimica = false;
        this.fisica = false;
        this.estadistica =  false;
        this.cs_basicas = true;
        break;
    }
  }

}
