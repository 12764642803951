import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-evento-vinculacion',
  templateUrl: './evento-vinculacion.component.html',
  styleUrls: ['./evento-vinculacion.component.css']
})
export class EventoVinculacionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
